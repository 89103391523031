import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import Text from 'antd/lib/typography/Text'
import axios from 'axios'
import { flatten } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Carousel as CarouselSlider } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import ROOT from '../../client'
import { saveCarouselSlots, saveImageKeys } from '../../store/actions'
import customTost from '../Notification'
import './carousel.scss'
import Redaction from './redaction'
import RenderImage from './RenderImage'

const DeleteText = () => (
	<div
		style={{
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50% , -50%)',
			zIndex: 99,
		}}
	>
		<Text
			style={{
				color: 'white',
				backgroundColor: 'red',
				padding: '7px 11px',
			}}
		>
			Delete Mode
		</Text>
	</div>
)

let cancelTokenOcrData

const Carousel = (props) => {
	const dispatch = useDispatch()
	const {
		mappingImageKey,
		imageLoadCount,
		isConfigureFieldEnable,
		selectedBucket,
		carouselSlots,
		imageKeys,
		maskedBucket,
		view,
		envars,
		isDeleteModeOn,
	} = useSelector((store) => store.storeProps)
	// eslint-disable-next-line
	const { queryData, selectedOption, setIsLoading, setIsDataLoading, minioClient } = props
	const [toggleCarousel, setToggleCarousel] = useState(false)
	const [imgIndex, setImgIndex] = useState(0)
	const [carouselData, setCarouselData] = useState([])
	const [lastIndex, setLastIndex] = useState(0)
	const [currentSlot, setcurrentSlot] = useState(0)
	const initialLoadCount = 5
	const carouselSize = 100
	const [selectedSlide, setselectedSlide] = useState(0)
	const [ocrData, setOcrData] = useState([])
	const [imageText, setImageText] = useState({
		status: false,
		bbStatus: false,
		text: '',
		message: 'Please click over the image to find the text.',
		top: 0,
		left: 0,
		width: 0,
		height: 0,
	})
	const [gettingData, setGettingData] = useState(false)
	const [isRedacting, setRedacting] = useState(false)
	const isSkipGlobalLoading = useRef(false)

	const [redImage, setRedImage] = useState('')
	const [form] = Form.useForm()

	const getCurrentImage = () => {
		const imgData = carouselData[currentSlot][imgIndex]

		return imgData?.data?.screenshot_key || ''
	}
	useEffect(() => {
		isSkipGlobalLoading.current = isRedacting
	}, [isRedacting])

	useEffect(() => {
		window.addEventListener('keydown', clickHandler)
		return () => window.removeEventListener('keydown', clickHandler)
	})

	// call fetch image whenever user searches query
	useEffect(() => {
		let unmount = false
		try {
			if (!unmount) {
				fetchImage(0, initialLoadCount)
				setToggleCarousel(!toggleCarousel)
				dispatch(saveCarouselSlots(['1']))
			}
		} catch (error) {
			console.log(error)
		}
		return () => {
			unmount = true
		}
		// eslint-disable-next-line
	}, [queryData])

	useEffect(() => {
		try {
			// reset real-time image on view change
			setRedImage('')
			setImageText({
				status: view === 'realTimeRedaction' || view === 'masked' ? true : false,
				bbStatus: false,
				text: '',
				message: 'Please click over the image to find the text.',
				top: 0,
				left: 0,
				width: 0,
				height: 0,
			})
		} catch (error) {
			console.log(error)
		}
		// eslint-disable-next-line
	}, [view])

	useEffect(() => {
		if (imgIndex === 0 && carouselData.length) {
			getOcrData(0, 0)
		}
	}, [carouselData])

	const splitArrIntoChunks = (array) => {
		let chunkSize = carouselSize
		let i,
			j,
			accum = []

		for (i = 0, j = array.length; i < j; i += chunkSize) {
			accum = [...accum, array.slice(i, i + chunkSize)]
		}

		return accum
	}

	const [isPending, setIsPending] = useState(true)

	// fetching images from server
	const fetchImage = async (index, imgCount) => {
		try {
			if (queryData.length && selectedBucket) {
				const endIndex =
					queryData.length >= index + Number(imgCount) ? index + Number(imgCount) : queryData.length
				setLastIndex(endIndex)
				const apiPath = `${ROOT}/api/image_carousel`
				let mappingKeys = queryData.map((data) => data[mappingImageKey])
				const slicedKeys = mappingKeys.slice(index, endIndex)
				if (slicedKeys?.length) {
					setIsPending(true)
					setIsDataLoading(true)
					const payLoad = {
						originalImagesDetails: {
							screenshotKeys: slicedKeys,
							bucketName: selectedBucket?.bucketName ? selectedBucket.bucketName : '',
							metaInfo: selectedBucket?.metaInfo ? selectedBucket.metaInfo : '',
							bucketFolderName: selectedBucket?.bucketFolderName
								? selectedBucket.bucketFolderName
								: '',
						},
						maskedImagesDetails: maskedBucket
							? {
									screenshotKeys: slicedKeys,
									bucketName: maskedBucket?.bucketName ? maskedBucket.bucketName : '',
									metaInfo: maskedBucket?.metaInfo ? maskedBucket.metaInfo : '',
									bucketFolderName: selectedBucket?.bucketFolderName
										? selectedBucket.bucketFolderName
										: '',
							  }
							: null,
					}
					if (envars?.storageMechanism === 'minio' && minioClient?.firstBucket) {
						let promises = slicedKeys.map((imageName) => {
							return minioClient.firstBucket
								.presignedGetObject(selectedBucket.bucketName, imageName)
								.then((res) => {
									return {
										screenshotKey: imageName,
										url: res,
										error: false,
									}
								})
								.catch(() => {
									return {
										screenshotKey: imageName,
										url: null,
										error: true,
									}
								})
						})
						Promise.all(promises)
							.then((results) => {
								if (results) {
									const allUrls = {
										originalImagesUrls: results,
									}
									if (maskedBucket && minioClient?.secondBucket) {
										let maskPromises = slicedKeys.map((imageName) => {
											return minioClient.secondBucket
												.presignedGetObject(maskedBucket.bucketName, imageName)
												.then((res) => {
													return {
														screenshotKey: imageName,
														url: res,
														error: false,
													}
												})
												.catch(() => {
													return {
														screenshotKey: imageName,
														url: null,
														error: true,
													}
												})
										})
										Promise.all(maskPromises)
											.then((maskResults) => {
												if (maskResults) {
													const existErrorB2 = maskResults.find((s) => s.error === true)
													if (existErrorB2) {
														customTost({
															type: 'error',
															message: 'Please check your second bucket configuration.',
														})
													}
													const existErrorB1 = results.find((s) => s.error === true)
													if (existErrorB1) {
														customTost({
															type: 'error',
															message: 'Please check your first bucket configuration.',
														})
													}
													allUrls.maskedImagesUrls = maskResults
													getImages(allUrls, index, endIndex)
												}
											})
											.catch((e) => {
												console.log('promise catch: ', e)
												console.error(e)
												setIsDataLoading(false)
											})
									} else {
										const existError = results.find((s) => s.error === true)
										if (existError) {
											customTost({
												type: 'error',
												message: 'Please check your first bucket configuration.',
											})
										}
										getImages(allUrls, index, endIndex)
									}
								}
							})
							.catch((e) => {
								setIsDataLoading(false)
								console.error(e)
							})
					} else {
						await axios
							.post(apiPath, payLoad)
							.then((response) => {
								if (response.data) {
									getImages(response.data, index, endIndex)
								}
							})
							.catch((error) => {
								setIsDataLoading(false)
								if (error?.response?.data?.message) {
									customTost({
										type: 'error',
										message: error.response.data.message,
									})
								}
							})
					}
				} else {
					setIsDataLoading(false)
					setIsPending(false)
				}
				if (index === 0) {
					setselectedSlide(0)
					setImgIndex(0)
				}
			} else {
				handlePageLoadingState()
				setIsDataLoading(false)
			}
		} catch (error) {
			handlePageLoadingState()
			customTost({
				type: 'error',
				message: 'Something went wrong. Please try later.',
			})
			console.log(error)
		}
	}

	const getOcrData = (index, slotIndex) => {
		try {
			if (envars.deployEnv !== 'aws') {
				setOcrData([])
				if (typeof cancelTokenOcrData != typeof undefined) {
					cancelTokenOcrData.cancel('Operation canceled due to new request.')
				}
				cancelTokenOcrData = axios.CancelToken.source()
				if (view === 'realTimeRedaction' || view === 'masked') {
					setGettingData(true)
					if (
						carouselData.length &&
						carouselData[slotIndex].length &&
						carouselData[slotIndex][index]?.data?.screenshot_key
					) {
						const screenshotKey = carouselData[slotIndex][index].data.screenshot_key
						setImageText({
							...imageText,
							status: true,
							bbStatus: false,
							text: '',
							message: 'Fetching OCR data. Please wait...',
						})
						axios
							.get(`${ROOT}/api/get-ocr-data?screenshotKey=${screenshotKey}`, {
								cancelToken: cancelTokenOcrData.token,
							})
							.then((res) => {
								setGettingData(false)
								setImageText({
									...imageText,
									status: true,
									bbStatus: false,
									text: '',
									message: res?.data?.length
										? 'Please click over the image to find the text.'
										: 'OCR data is not available when masking is in progress.',
								})
								if (res?.data?.length) {
									setOcrData(res.data)
								}
							})
							.catch((err) => {
								if (axios.isCancel(err))
									setImageText({
										...imageText,
										status: true,
										bbStatus: false,
										text: '',
										message: 'Something went wrong. Please wait or try again.',
									})
							})
					}
				}
			}
		} catch (err) {
			console.log(err)
		}
	}

	const getImages = (responseUrls, index, endIndex) => {
		try {
			if (responseUrls) {
				let updatedUrls = []
				responseUrls.originalImagesUrls.forEach((values, idx) => {
					const url = values && values.url && values.url !== null ? values.url : './no-image.png'
					const screenshotKey =
						values && values.screenshotKey && values.screenshotKey !== null
							? values.screenshotKey
							: ''
					let maskedUrl = ''
					let maskedScreenshotKey = ''

					if (responseUrls?.maskedImagesUrls?.[idx]) {
						const maskedVal = responseUrls.maskedImagesUrls[idx]
						maskedUrl =
							maskedVal && maskedVal.url && maskedVal.url !== null
								? maskedVal.url
								: './no-image.png'
						maskedScreenshotKey =
							maskedVal && maskedVal.screenshotKey && maskedVal.screenshotKey !== null
								? maskedVal.screenshotKey
								: ''
					}

					updatedUrls.push({
						url,
						screenshotKey,
						maskedUrl,
						maskedScreenshotKey,
					})
				})
				let carouselAlldata = [...carouselData]
				let allKeys = imageKeys?.length ? [...imageKeys] : []
				allKeys = flatten(allKeys)
				carouselAlldata = flatten(carouselAlldata)
				for (let i = index, j = 0; i < endIndex || j < updatedUrls.length; i++, j++) {
					carouselAlldata.push({
						index: i,
						data: queryData[i],
						url: updatedUrls[j]?.url,
						maskedUrl: updatedUrls[j]?.maskedUrl,
					})
					if (updatedUrls[j]?.screenshotKey) {
						allKeys.push(updatedUrls[j]?.screenshotKey)
					}
				}
				const allKeysChunks = splitArrIntoChunks(allKeys)
				dispatch(saveImageKeys(allKeysChunks))
				setCarouselData(splitArrIntoChunks(carouselAlldata))
			}
			setTimeout(() => {
				handlePageLoadingState()
				setIsDataLoading(false)
			}, 3000)
		} catch (error) {
			console.log(error)
		}
	}
	/* istanbul ignore next */
	const handleImageLoad = (event, eventName, eventFlag) => {
		let imgnewIndex = carouselSize * currentSlot + imgIndex
		let newEvent = carouselSize * currentSlot + event
		if (lastIndex === initialLoadCount) {
			fetchImage(lastIndex, imageLoadCount)
		} else {
			if (eventName === 'next' && lastIndex === imgnewIndex + Number(imageLoadCount)) {
				fetchImage(lastIndex, imageLoadCount)
			}
			if ((eventName === 'bulletDown' || eventName === 'bulletUp') && lastIndex === newEvent + 1) {
				fetchImage(lastIndex, imageLoadCount)
			}
		}
		if (eventFlag) setselectedSlide(99)
		setImgIndex(event)
	}
	/* istanbul ignore next */
	const handleChange = (event) => {
		try {
			let eventName = ''
			if (imgIndex === 0 && event === 0 && currentSlot === 0) return
			// hit redaction API on image change (when view is redaction)
			if (view === redactionView) {
				// reset real-time-image on image change
				setRedImage('')
				form.submit()
			}
			if (imgIndex < event) {
				eventName = imgIndex + 1 === event ? 'next' : 'bulletDown'
			} else {
				eventName = imgIndex - 1 === event ? 'prev' : 'bulletUp'
			}
			if (imgIndex === 99 && eventName !== 'prev') {
				setselectedSlide(0)
			}
			if (event >= carouselSize - 1 && imgIndex === 99) {
				if (isPending) {
					if (carouselSlots?.length) {
						const newSlot = currentSlot + 2
						const savedSlots = [...carouselSlots]
						const isExist = savedSlots.includes(newSlot)
						if (!isExist) {
							savedSlots.push(newSlot)
							dispatch(saveCarouselSlots(savedSlots))
						}
					}
					setcurrentSlot(currentSlot + 1)
					setToggleCarousel(!toggleCarousel)
					handleImageLoad(0, eventName, false)
					getOcrData(0, currentSlot + 1)
				}
			} else if (
				event <= 0 &&
				imgIndex === 0 &&
				currentSlot !== 0 &&
				eventName !== 'next' &&
				eventName !== 'bulletDown'
			) {
				if (!isPending) {
					setIsPending(true)
				}
				setcurrentSlot(currentSlot - 1)
				setToggleCarousel(!toggleCarousel)
				handleImageLoad(99, eventName, true)
				getOcrData(99, currentSlot - 1)
			} else if (lastIndex !== imgIndex + 1) {
				handleImageLoad(event, eventName, false)
				getOcrData(event, currentSlot)
			} else {
				setImgIndex(event)
				getOcrData(event, 0)
				setIsDataLoading(false)
			}
		} catch (error) {
			console.log(error)
		}
	}

	// move images in carousel by keyboard keys
	/* istanbul ignore next */
	const clickHandler = (e) => {
		try {
			if (e?.target?.nodeName === 'DIV' || e?.target?.nodeName === 'BUTTON') {
				if (e.ctrlKey) {
					if (e.keyCode === 38) {
						e.preventDefault()
						return document.getElementsByClassName('control-dots')[0]?.firstElementChild?.click()
					}
				} else {
					if (e.keyCode === 38) {
						e.preventDefault()
						return document.getElementsByClassName('control-dots')[0]?.firstElementChild?.click()
					}
					if (e.keyCode === 40) {
						e.preventDefault()
						return document.getElementsByClassName('control-dots')[0]?.lastElementChild?.click()
					}
				}
			}
		} catch (error) {
			console.log(error)
		}
	}

	const formatStatus = (currentItem, total) => {
		return (
			<>
				<strong>Slot {currentSlot + 1}:</strong> &nbsp;{currentItem} of {total}
			</>
		)
	}

	const imageDiv = useRef(null)
	const redactionView = 'realTimeRedaction'
	const handleRedactionProcess = (img) => {
		setRedImage(img)
	}

	const handlePageLoadingState = () => {
		if (view === redactionView && isSkipGlobalLoading.current) {
			return
		}

		setIsLoading(false)
	}

	return (
		<>
			{view === redactionView && (
				<>
					<Redaction
						form={form}
						getSelectedImg={getCurrentImage}
						handleRedactionProcess={handleRedactionProcess}
						setIsLoading={setIsLoading}
						isLocLoading={isRedacting}
						setLocLoading={setRedacting}
					/>
				</>
			)}

			{carouselData.length > 0 && (
				<div className='carouselWrapper'>
					{imageText.status && envars.deployEnv !== 'aws' && (
						<div className='selectedText'>
							{imageText.message} <strong>{imageText.text}</strong>
							<span onClick={() => setImageText({ ...imageText, status: false })}>
								<img src='./close-icon.svg' />
							</span>
						</div>
					)}
					{isDeleteModeOn && <DeleteText />}
					<CarouselSlider
						className={`carousel ${isConfigureFieldEnable ? '' : 'noFieldSelected'} ${
							isDeleteModeOn && 'delete-border'
						}`}
						onChange={(event) => handleChange(event)}
						showThumbs={false}
						key={toggleCarousel}
						autoFocus={true}
						selectedItem={selectedSlide}
						useKeyboardArrows={true}
						statusFormatter={formatStatus}
						infiniteLoop={false}
						renderArrowNext={(onClickHandler) => (
							<button onClick={onClickHandler} className='right-arrow-button'>
								<RightOutlined className='right-outlined' />
							</button>
						)}
						renderArrowPrev={(onClickHandler) => (
							<button onClick={onClickHandler} className='left-arrow-button'>
								<LeftOutlined className='left-outlined' />
							</button>
						)}
					>
						{carouselData[currentSlot] &&
							carouselData[currentSlot].map((res, ind) => {
								return (
									<div className={`img-wrapper img-wrapper_${ind}`} key={`img-wrapper_${ind}`}>
										{isConfigureFieldEnable && (
											<div className={`img-data img-data_${ind}`} key={`img-data_${ind}`}>
												{res.data &&
													Object.keys(res.data).map((elm, index) => {
														return selectedOption.map((opt) => {
															if (elm === opt.value && opt.isChecked === true) {
																return (
																	<div
																		style={{
																			wordBreak: `${elm === 'url' ? 'break-all' : 'unset'}`,
																		}}
																		className='fieldInfo'
																		key={`dataOptVal_${index}`}
																	>
																		<div className='fieldValue'>
																			<strong>{elm}:</strong>{' '}
																			{res.data[elm] === true
																				? 'Yes'
																				: res.data[elm] === false
																				? 'No'
																				: res.data[elm] || typeof res.data[elm] === 'number'
																				? res.data[elm]
																				: 'NA'}
																		</div>
																	</div>
																)
															} else {
																return null
															}
														})
													})}
											</div>
										)}
										<div
											ref={imageDiv}
											className='imageContainer'
											id={'imageDiv' + ind}
											key={'imageDiv' + ind}
										>
											{imageText.status && (
												<div
													style={{
														display: imageText.bbStatus ? 'block' : 'none',
														position: 'absolute',
														top: imageText.top ? imageText.top + 'px' : '0px',
														left: imageText.left ? imageText.left + 'px' : '0px',
														width: imageText.width ? imageText.width + 'px' : '0px',
														height: imageText.height ? imageText.height + 'px' : 'px',
														background: '#f05742',
														opacity: '0.3',
														zIndex: '9',
													}}
												></div>
											)}
											<RenderImage
												imageData={
													view === redactionView
														? { ...res, maskedUrl: redImage?.url || res.maskedUrl }
														: res
												}
												setImageText={setImageText}
												imageText={imageText}
												ocrData={ocrData}
												gettingData={gettingData}
											/>
										</div>
									</div>
								)
							})}
					</CarouselSlider>
				</div>
			)}
		</>
	)
}

Carousel.propTypes = {
	queryData: PropTypes.array,
	selectedOption: PropTypes.array,
	setIsLoading: PropTypes.func,
	setIsDataLoading: PropTypes.func,
	minioClient: PropTypes.any,
}

export default Carousel
