import { CloseOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import './style.scss'

// Component to highlight new word added
const BadgeComponent = (props) => {
	const { list, deleteItem, duplicateItems } = props

	if (!list.length) {
		return <ul className='badge_list' />
	}

	return (
		<>
			<ul className='badge_list'>
				{list.map((val, index) => {
					return (
						<li
							key={index}
							className={`${duplicateItems.includes(val.label.toLowerCase()) ? 'duplicate' : ''}`}
						>
							<div
								key={`query_${index}`}
								className={`badge_list_item ${
									duplicateItems.includes(val.label.toLowerCase()) ? 'duplicate' : ''
								}`}
							>
								<div className='badge_list_label'>{val.label}</div>
								<CloseOutlined
									style={{ cursor: 'pointer', fontSize: '12px', color: 'white', padding: '7px' }}
									className='delete-form'
									onClick={() => deleteItem(val)}
								/>
							</div>
						</li>
					)
				})}
			</ul>
		</>
	)
}

BadgeComponent.propTypes = {
	list: PropTypes.array,
	deleteItem: PropTypes.func,
	duplicateItems: PropTypes.array,
}

export default BadgeComponent
