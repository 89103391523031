import { ExportOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import axios from 'axios'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import customTost from '../Notification'

const ExportQuery = (props) => {
	const {
		indexValue,
		indexAlias,
		aliasFlag,
		filters,
		observedAtDetail,
		selectedBucket,
		queryStack,
	} = useSelector((store) => store.storeProps)
	const bucketName = selectedBucket?.bucketName ? selectedBucket.bucketName : ''
	const metaInfo = selectedBucket?.metaInfo ? selectedBucket.metaInfo : ''
	const { enableExport, finalQuery, minioClient } = props
	const [fileUrl, setFileUrl] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		try {
			if (fileUrl) {
				downloadFile()
			}
		} catch (error) {
			console.log(error)
		}
	}, [fileUrl])

	const downloadFile = () => {
		try {
			setIsLoading(false)
			const downloadQueryBtn = document.getElementById('downloadQuery')
			if (downloadQueryBtn) {
				downloadQueryBtn.click()
			}
		} catch (error) {
			console.log('Download file error: ', error)
		}
	}

	const handleExport = () => {
		try {
			if (queryStack && observedAtDetail && filters) {
				setIsLoading(true)
				const queryData = {
					index: indexValue,
					alias: aliasFlag ? indexAlias : '',
					groups: filters ? filters : '',
					date: observedAtDetail && observedAtDetail.dateRange ? observedAtDetail.dateRange : '',
					bucketName: bucketName,
					metaInfo: metaInfo,
					bucketFolderName: selectedBucket?.bucketFolderName ? selectedBucket.bucketFolderName : '',
				}
				axios
					.post('/api/export-query', queryData)
					.then((res) => {
						if (res?.data?.signedUrl) {
							setFileUrl(res.data.signedUrl)
						} else {
							if (res?.data?.fileName && minioClient?.firstBucket) {
								minioClient.firstBucket
									.presignedGetObject(selectedBucket.bucketName, res.data.fileName)
									.then((signedUrl) => {
										if (signedUrl) {
											setFileUrl(signedUrl)
										} else {
											setIsLoading(false)
											customTost({
												type: 'error',
												message: 'Something went wrong. Please try later.',
											})
										}
									})
									.catch((error) => {
										setIsLoading(false)
										console.log('Export API error: ', error)
									})
							}
						}
						if (res?.data?.statusCode === 400) {
							setIsLoading(false)
							customTost({
								type: 'error',
								message: res.data.message
									? res.data.message
									: 'Something went wrong. Please try later.',
							})
						}
					})
					.catch((error) => {
						setIsLoading(false)
						customTost({
							type: 'error',
							message: 'Something went wrong. Please try later.',
						})
						console.log('Export API error: ', error)
					})
			}
		} catch (error) {
			setIsLoading(false)
			console.log('Export error: ', error)
		}
	}
	return (
		<>
			<Button
				id='exportQueryButton'
				loading={isLoading}
				disabled={finalQuery ? (enableExport ? false : true) : true}
				icon={<ExportOutlined />}
				type='primary'
				onClick={handleExport}
			>
				Export Query
			</Button>
			<a href={fileUrl} id='downloadQuery' style={{ display: 'none' }} download='KLitequery'>
				Download
			</a>
		</>
	)
}

ExportQuery.propTypes = {
	minioClient: PropTypes.object,
	finalQuery: PropTypes.string,
	enableExport: PropTypes.bool,
}

export default ExportQuery
