import { CloseOutlined, FormOutlined } from '@ant-design/icons'
import { Checkbox, Switch, Tooltip } from 'antd'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { saveQueryStack } from '../../store/actions'
import customTost from '../Notification'
import { generateConfig } from '../Search/config'

const QueryStackComponent = ({
	setEditIndex,
	setQueryDetailArr,
	setEditFilter,
	setAddFilter,
	setUpdateSignatureFlag,
	setEditInitialVals,
	setIsValueMatch,
	setImportFile,
	setEditLoader,
}) => {
	const dispatch = useDispatch()
	const { indexValue, indexAlias, aliasFlag, observedAtDetail, imageDetail, queryStack } =
		useSelector((store) => store.storeProps)

	const onCheckboxChange = (e, index) => {
		try {
			if (queryStack.length) {
				if (!e?.target?.checked) {
					setEditFilter(false)
				}
				const newStack = queryStack.map((val, i) => {
					if (e?.target?.value && val.query === e.target.value) {
						val = {
							...val,
							isChecked: e.target.checked,
							allowOperand: queryStack[index + 1]
								? queryStack[index + 1]?.isChecked
									? e.target.checked
									: false
								: e.target.checked,
						}
					}
					if (index - 1 === i) {
						val = {
							...val,
							allowOperand: queryStack[index - 1]?.isChecked ? e.target.checked : false,
						}
					}
					return val
				})
				dispatch(saveQueryStack(newStack))
			}
		} catch (error) {
			console.log('Error [onCheckboxChange]: ', error)
		}
	}

	const editQuery = (value, index) => {
		try {
			setEditIndex(index)
			if (value) {
				if (queryStack[index]?.isChecked) {
					setEditLoader(true)
					setIsValueMatch(false)
					value = value.trim()
					let queryDetail = []
					let initVals = {}
					if (
						value &&
						queryStack.length &&
						queryStack[index] &&
						queryStack[index].field &&
						queryStack[index].fieldValue &&
						queryStack[index].operator
					) {
						let key = queryStack[index].field
						let op = queryStack[index].operator
						let fieldVal = queryStack[index].fieldValue
						let startR = ''
						let endR = ''
						const valueDetail = []
						let fieldType = ''
						imageDetail.forEach((val) => {
							if (val?.value === key) {
								valueDetail.push(val)
								fieldType = val.type
							}
						})
						initVals = {
							[key]: fieldType !== 'boolean' ? fieldVal : JSON.parse(fieldVal),
						}
						if (op === 'BETWEEN' || op === 'NOT BETWEEN') {
							const rangeArr = fieldVal.split(' AND ')
							startR = rangeArr[0]
							endR = rangeArr[1]
							fieldVal = ''
							if (fieldType !== 'date') {
								initVals = {
									startNumberRange: startR,
									endNumberRange: endR,
								}
							} else {
								initVals = {
									[key]: [moment(startR), moment(endR)],
								}
							}
						}
						generateConfig(
							indexValue,
							indexAlias,
							aliasFlag,
							valueDetail,
							observedAtDetail.startDate,
							observedAtDetail.endDate,
						)
							.then((res) => {
								if (res?.fields) {
									const fieldDetail = res.fields
									queryDetail.push({
										field: key,
										operator: op.toUpperCase(),
										values:
											fieldDetail[key]?.options && fieldDetail[key]?.options.length
												? fieldDetail[key].options
												: [],
										type: fieldDetail[key]?.type ? fieldDetail[key].type : '',
										fieldValue: fieldVal,
										startRange: startR,
										endRange: endR,
									})
									setQueryDetailArr(queryDetail)
									setEditFilter(true)
									setAddFilter(false)
									setUpdateSignatureFlag(false)
									setImportFile(false)
									setEditInitialVals(initVals)
									setEditLoader(false)
								}
							})
							.catch((error) => {
								console.log('error: ', error)
								setEditLoader(false)
							})
					}
				} else {
					setEditLoader(false)
					customTost({
						type: 'error',
						message: 'Filter is disabled. Please enable before edit.',
					})
				}
			}
		} catch (error) {
			console.log('Error [editQuery]: ', error)
		}
	}

	const deleteQuery = (value, index) => {
		try {
			if (queryStack.length) {
				const newStack = []
				queryStack.forEach((val, i) => {
					if (value && val.query !== value) {
						if (index - 1 === i) {
							val = {
								...val,
								allowOperand: true,
								operand:
									val.operand === 'AND'
										? 'AND'
										: `${index - 1 === queryStack.length - 2 ? 'AND' : 'OR'}`,
							}
						}
						newStack.push(val)
					}
				})
				// setQueryStack(newStack);
				dispatch(saveQueryStack(newStack))
				setEditFilter(false)
				setAddFilter(false)
				setUpdateSignatureFlag(false)
			}
		} catch (error) {
			console.log('Error [deleteQuery]: ', error)
		}
	}

	const onOperandChange = (e, index) => {
		try {
			if (queryStack.length) {
				const newOperandVal = e ? 'AND' : 'OR'
				let newStack = []
				queryStack.forEach((val, i) => {
					if (i === index) {
						val = { ...val, operand: newOperandVal }
					}
					newStack.push(val)
				})
				// setQueryStack(newStack);
				dispatch(saveQueryStack(newStack))
			}
		} catch (error) {
			console.log('Error [onOperandChange]: ', error)
		}
	}

	return (
		<>
			<ul className='queryList'>
				{queryStack.map((val, index) => {
					return (
						<li
							key={index}
							className={`${val.braces} ${
								index + 1 === queryStack.length
									? queryStack.length === 1
										? ''
										: 'endFilter'
									: index === 0
									? 'startFilter'
									: 'midFilter'
							}`}
						>
							<div key={`query_${index}`} className={`queryBlock`}>
								<Checkbox
									checked={val.isChecked}
									id={`checkbox_${index}`}
									key={`checkbox_${index}`}
									onChange={(e) => onCheckboxChange(e, index)}
									value={val.query}
								/>
								<div className='query'>{val.query}</div>
								<Tooltip placement='bottom' arrowPointAtCenter title='Edit Filter'>
									<FormOutlined className='edit-form' onClick={() => editQuery(val.query, index)} />
								</Tooltip>
								<Tooltip placement='bottomRight' arrowPointAtCenter title='Remove Filter'>
									<CloseOutlined
										className='delete-form'
										onClick={() => deleteQuery(val.query, index)}
									/>
								</Tooltip>
							</div>
							{index < queryStack.length - 1 && (
								<div key={`operand_${index}`} className={`operandOption ${index}`}>
									<Switch
										className='switch-form'
										style={{ backgroundColor: '#242323' }}
										size='small'
										key={`operand_switcher_${index}`}
										onChange={(e) => onOperandChange(e, index)}
										checkedChildren='AND'
										unCheckedChildren='OR'
										disabled={val.isChecked ? !val.allowOperand : true}
										checked={val.operand === 'AND' ? true : false}
									/>
								</div>
							)}
						</li>
					)
				})}
			</ul>
		</>
	)
}

QueryStackComponent.propTypes = {
	setEditIndex: PropTypes.func,
	setQueryDetailArr: PropTypes.func,
	setEditFilter: PropTypes.func,
	setAddFilter: PropTypes.func,
	setUpdateSignatureFlag: PropTypes.func,
	setEditInitialVals: PropTypes.func,
	setIsValueMatch: PropTypes.func,
	setImportFile: PropTypes.func,
	setEditLoader: PropTypes.func,
}

export default QueryStackComponent
