import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import axios from 'axios'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import ROOT from '../../../client'
import { createMC } from '../../../services'
import * as helper from '../../helper'
import CustomModal from '../../Modal'
import customTost from '../../Notification'

// get success message for pass/block list update
export const getSuccessMess = (str) => {
	return `The ${str} List updated successfully, and duplicate words (if any) have been removed to avoid errors.`
}

// Add Words - Fetch file from minio and add new words to the existing file
export const getAndModifieFile = (words, fileName, minioClient, setRefetchList) => {
	return new Promise((resolve, reject) => {
		try {
			if (words.addList?.length || words.removeList?.length) {
				let add = words.addList.map((i) => (i.endsWith(' 1') ? i : i + ' 1'))
				let remove = words.removeList.map((i) => (i.endsWith(' 1') ? i : i.toLowerCase() + ' 1'))

				const chunks = []
				let data
				if (minioClient) {
					/* istanbul ignore next */
					minioClient.getObject('dictionaries', fileName, function (err, dataStream) {
						if (err) {
							return console.log(err)
						}
						dataStream.on('data', function (chunk) {
							chunks.push(Buffer.from(chunk))
						})
						dataStream.on('end', function () {
							data = Buffer.concat(chunks).toString('utf-8')
							let arrOfData = data?.split('\n')
							let lastElement = arrOfData[arrOfData.length - 1]
							// Remove last empty element
							!lastElement && arrOfData.splice(arrOfData.length - 1, 1)

							if (remove.length) {
								remove.map((item) => {
									const ind = arrOfData.indexOf(item)
									if (ind !== -1) {
										arrOfData.splice(ind, 1)
									}
								})
							}

							makeNewData(arrOfData, add, fileName, minioClient, setRefetchList, resolve, reject)
						})
						dataStream.on('error', function (err) {
							reject(err?.message || '')
						})
					})
				}
			} else {
				resolve()
				return
			}
		} catch (err) {
			reject(err?.message || '')
		}
	})
}

// Append new words to existing list
export const makeNewData = (
	existingArr,
	newArr,
	fileName,
	minioClient,
	setRefetchList,
	resolve,
	reject,
) => {
	let combinedArr = [...existingArr, ...newArr]
	combinedArr = combinedArr.map((ele) => `"${ele.toLowerCase()}"`)
	combinedArr = helper.removeDuplicates(combinedArr)

	let commaSeparated = combinedArr.toString()
	createFile(fileName, commaSeparated, minioClient, setRefetchList, resolve, reject)
}

// Create a new file to be uploaded to minio
export const createFile = (
	fileName,
	wordsWithComma,
	minioClient,
	setRefetchList,
	resolve,
	reject,
) => {
	let newlineWords = wordsWithComma.replace(/","/g, '\n')
	newlineWords = newlineWords.replace(/(^"|"$)/g, '')
	let blob = new Blob([newlineWords], { type: 'text/plain' })

	try {
		let reader = new FileReader()
		reader.onload = function () {
			uploadToMinio(fileName, reader.result, minioClient, setRefetchList, resolve, reject)
		}

		reader.onerror = function () {
			reject(reader.error || '')
		}

		reader.readAsText(blob)
	} catch (error) {
		reject(error?.message || '')
	}
}

export const uploadToMinio = (fileName, stream, minioClient, setRefetchList, resolve, reject) => {
	// Upload file to minio
	try {
		if (minioClient) {
			minioClient.putObject('dictionaries', fileName, stream, function (err) {
				if (err) {
					reject('Something went wrong.')
				}
				setRefetchList()
				resolve()
			})
		}
	} catch (err) {
		reject('Something went wrong.')
	}
}

// Forwarding Tab - Deploy button click event
export const handlePegDeployment = async ({
	form,
	initialValues,
	setLocLoading,
	envars,
	passList,
	blockList,
	setRefetchList,
	clearPassLists,
}) => {
	try {
		setLocLoading(true)
		const minioClient = createMC(envars)
		const cleaning = form.getFieldValue('cleaning')
		const symspell__edit_distance = form.getFieldValue('symspell__edit_distance')
		const values = { cleaning, symspell__edit_distance }

		if (initialValues) {
			const updatedVars = initialValues.map((obj) => {
				if (Object.hasOwn(values, obj.name)) {
					const newVal = {
						name: obj.name,
						value: values[obj.name],
					}
					return newVal
				} else {
					return obj
				}
			})

			const res = await axios.post(`${ROOT}/api/update-deployment-envars`, {
				updatedVars,
				flag: true,
			})
			const { data } = res

			if (res.status === 200) {
				// upload words in pass-list
				await getAndModifieFile(passList, 'pass-list.txt', minioClient, setRefetchList)
				// upload words in block-list
				await getAndModifieFile(blockList, 'block-list.txt', minioClient, setRefetchList)
				// hit the conformation API (to tell backend pass/block list uploaded)
				// clear pass/block list and form
				clearPassLists()

				if (
					(passList.addList.length || passList.removeList.length) &&
					(blockList.addList.length || blockList.removeList.length)
				) {
					customTost({
						type: 'success',
						message: getSuccessMess('Pass/Block'),
					})
				} else if (passList.addList.length || passList.removeList.length) {
					customTost({
						type: 'success',
						message: getSuccessMess('Pass'),
					})
				} else if (blockList.addList.length || blockList.removeList.length) {
					customTost({
						type: 'success',
						message: getSuccessMess('Block'),
					})
				}
			} else {
				customTost({
					type: 'error',
					message: data?.message || 'Internal server error.',
				})
			}
		}

		setLocLoading(false)
	} catch (err) {
		setLocLoading(false)
		customTost({
			type: 'error',
			message: err?.response?.data?.message || 'Internal server error.',
		})
	}
}

const RedactionModal = (props) => {
	const {
		isModalOpen,
		setModelOpen,
		form,
		setLocLoading,
		envars,
		passList,
		blockList,
		setRefetchList,
		clearPassLists,
	} = props
	const [initialValues, setInitialValues] = useState([])

	useEffect(() => {
		// Fetch and set Forwarding form values
		try {
			axios.get(`${ROOT}/api/get-deployment-envars`).then((res) => {
				if (res?.data?.envData !== {}) {
					res.data.envData.reduce((modArr, item) => {
						modArr[item.name] = item.value ? item.value : ''
						return modArr
					}, {})
				}
				const data = res?.data?.envData
				setInitialValues(data)
				const cleaning = data.find((i) => i.name === 'cleaning')
				const symspell = data.find((i) => i.name === 'symspell__edit_distance')
				if (form.setFieldsValue) {
					form.setFieldsValue({
						cleaning: cleaning?.value || 'false',
						symspell__edit_distance: symspell?.value || '0',
					})
				}
			})
		} catch (err) {
			return
		}

		// eslint-disable-next-line
	}, [])

	return (
		<>
			<CustomModal
				isOpen={isModalOpen}
				className='successModal modal_wrapper'
				onClose={() => setModelOpen(false)}
				footer={[
					<Button onClick={() => setModelOpen(false)} key='btnClose' id='btnClose'>
						No, Cancel
					</Button>,
					<Button
						className='r-commit'
						onClick={() => {
							handlePegDeployment({
								form,
								initialValues,
								setLocLoading,
								envars,
								passList,
								blockList,
								setRefetchList,
								clearPassLists,
							})
							setModelOpen(false)
						}}
						type='primary'
						key='btndownload'
					>
						Yes, Commit
					</Button>,
				]}
			>
				<div className='success_modal'>
					<ExclamationCircleOutlined style={{ color: '#FFCC00', fontSize: '50px' }} />
					<div className='success_modal_header'>Are you sure?</div>
					<div className='success_modal_sub'>
						All redaction changes will be saved and will be used in the next newly masked images.
					</div>
				</div>
			</CustomModal>
		</>
	)
}

RedactionModal.propTypes = {
	isModalOpen: PropTypes.bool,
	setModelOpen: PropTypes.func,
	form: PropTypes.any,
	setLocLoading: PropTypes.func,
	envars: PropTypes.object,
	passList: PropTypes.object,
	blockList: PropTypes.object,
	setRefetchList: PropTypes.func,
	clearPassLists: PropTypes.func,
}

export default RedactionModal
