import {
	DeleteOutlined,
	LockOutlined,
	PictureOutlined,
	PoweroffOutlined,
	ProfileOutlined,
	SettingOutlined,
} from '@ant-design/icons'
import { Menu, Typography } from 'antd'
import axios from 'axios'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ROOT from '../../client'
import {
	logOutAction,
	saveDate,
	saveQueryStack,
	saveTokenFlag,
	setDeleteMode,
	updateEnvarsStatus,
} from '../../store/actions'
import customTost from '../Notification'
import ResetPassword from '../ResetPassword'
import './settings.scss'

const { SubMenu } = Menu
const { Link } = Typography
const PEG_URL = '/peg'

const UserProfileMenu = ({ setIsLoading, setVisibleMenu }) => {
	const dispatch = useDispatch()
	const [isResetModalOpen, setResetModalOpen] = useState(false)

	let navigate = useNavigate()

	const logoutHandler = (source) => {
		try {
			setIsLoading(true)
			if (source === '/') {
				dispatch(updateEnvarsStatus(true))
			}
			const apiPath = `${ROOT}/api/logout`
			axios
				.post(apiPath)
				.then((resp) => {
					if (resp?.data?.statusCode === 200) {
						setIsLoading(false)
						// dispatch logout action
						dispatch(logOutAction())
						navigate(source)
					}
				})
				.catch((error) => {
					setIsLoading(false)
					dispatch(saveTokenFlag(false))
					console.log(error)
					customTost({
						type: 'error',
						message: error?.response?.data?.message,
					})
					return
				})
		} catch (error) {
			console.log('Error: [Settings]: ', error)
		}
	}

	const handleMenuOnclick = () => {
		setVisibleMenu(false)
	}
	return (
		<>
			<ResetPassword
				isOpen={isResetModalOpen}
				onClose={() => setResetModalOpen(false)}
				setIsLoading={setIsLoading}
				logoutHandler={logoutHandler}
			/>
			<Menu className='profile-settings' onClick={handleMenuOnclick}>
				<Menu.Item key='7' className='menu-item-wrapper' onClick={() => setResetModalOpen(true)}>
					<LockOutlined />
					Reset Password
				</Menu.Item>

				<Menu.Item
					key='9'
					className='menu-item-wrapper logOutBtn'
					onClick={() => logoutHandler('/')}
				>
					<PoweroffOutlined />
					Logout
				</Menu.Item>
			</Menu>
		</>
	)
}

const SettingsMenu = ({ setVisibleMenu }) => {
	const {
		adminUserFlag,
		envars,
		deleteUUID,
		noRecordsAvailable,
		searchData,
		isDeleteModeOn,
		indexValue,
		indexAlias,
		mappingImageKey,
		aliasIndexesArr,
	} = useSelector((store) => store.storeProps)
	const allAliasIndexes = aliasIndexesArr && aliasIndexesArr.length ? aliasIndexesArr : []
	const { deployEnv } = envars
	let location = useLocation()
	const dispatch = useDispatch()
	const showDeleteMenu = deployEnv !== 'aws' && adminUserFlag && location.pathname !== PEG_URL
	const handleBulkDeleteOpen = () => {
		if (deleteUUID && deleteUUID !== '')
			return customTost({
				type: 'error',
				message: 'Delete Action is already running. Please close that.',
			})

		// Setting default date range to start delete mode
		dispatch(
			saveDate({
				dateRange: '',
				startDate: 'now-15d',
				endDate: 'now',
			}),
		)
		dispatch(saveQueryStack([]))
		dispatch(setDeleteMode({ isDeleteModeOn: true }))
	}

	return (
		<Menu className='profile-settings' onClick={() => setVisibleMenu(false)}>
			<Menu.Item key='1' className='menu-item-wrapper disabled-item'>
				<ProfileOutlined rotate={180} />
				Index Pattern: <span className='menu-item-val'>{indexValue}</span>
			</Menu.Item>
			{indexAlias && (
				<>
					{allAliasIndexes.length > 0 ? (
						<>
							<SubMenu
								key='sub1'
								className='aliasSubmenu'
								icon={<ProfileOutlined rotate={180} />}
								title={
									<>
										Alias: <span className='aliasValue'>{indexAlias}</span>
									</>
								}
							>
								{allAliasIndexes.map((val) => {
									return <Menu.Item key={val}>{val}</Menu.Item>
								})}
							</SubMenu>
						</>
					) : (
						<Menu.Item key='aliasMenu' className='menu-item-wrapper disabled-item'>
							<ProfileOutlined rotate={180} />
							Alias: <span className='menu-item-val'>{indexAlias}</span>
						</Menu.Item>
					)}
				</>
			)}
			<Menu.Item key='5' className='menu-item-wrapper disabled-item'>
				<PictureOutlined />
				Image Key: <span className='menu-item-val'>{mappingImageKey}</span>
			</Menu.Item>

			{adminUserFlag && deployEnv !== 'aws' && (
				<Menu.Item key='6' className='menu-item-wrapper bottomBorder'>
					<SettingOutlined />
					<Link className='link_color' href={PEG_URL} target='_blank'>
						Peg Configuration
					</Link>
				</Menu.Item>
			)}
			{showDeleteMenu &&
				(!isDeleteModeOn ? (
					!noRecordsAvailable &&
					searchData?.length > 0 && (
						<Menu.Item key='8' className='menu-item-wrapper' onClick={handleBulkDeleteOpen}>
							<DeleteOutlined />
							Enable Bulk Delete
						</Menu.Item>
					)
				) : (
					<Menu.Item
						key='8'
						className='menu-item-wrapper'
						onClick={() => dispatch(setDeleteMode({ isDeleteModeOn: false }))}
					>
						<DeleteOutlined />
						Disable Bulk Delete
					</Menu.Item>
				))}
		</Menu>
	)
}

UserProfileMenu.propTypes = {
	setIsLoading: PropTypes.func,
	setVisibleMenu: PropTypes.func,
}

SettingsMenu.propTypes = {
	setVisibleMenu: PropTypes.func,
}

export { UserProfileMenu, SettingsMenu }
