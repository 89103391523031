import { CheckCircleTwoTone, SyncOutlined } from '@ant-design/icons'
import { Collapse, Table } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import './style.scss'

const { Panel } = Collapse

moment.locale('en', {
	relativeTime: {
		future: 'in %s',
		past: '%s ago',
		s: '1s',
		ss: '%ss',
		m: '1m',
		mm: '%dm',
		h: '1h',
		hh: '%dh',
		d: '1d',
		dd: '%dd',
		M: '1M',
		MM: '%dM',
		y: '1Y',
		yy: '%dY',
	},
})

const PODTable = (props) => {
	const { data, title, className, isLoading } = props
	const dates = data.map((d) => moment(d.podStartTime))
	const sortedDates = dates.sort((a, b) => moment(a) - moment(b))
	const min2 = sortedDates[sortedDates.length - 1]

	const renderIcon = (state) => {
		if (state.toLowerCase() === 'running')
			return (
				<div className='status_wapper'>
					<SyncOutlined style={{ color: '#7CA704' }} spin />
					{state}
				</div>
			)
		else if (state.toLowerCase() === 'succeeded')
			return (
				<div className='status_wapper'>
					<CheckCircleTwoTone twoToneColor='#52c41a' />
					Completed
				</div>
			)
		else return <>{state}</>
	}

	const calculateAge = (podStartTime) => {
		const dateFromNow = moment(podStartTime).fromNow(true)

		return <>{dateFromNow}</>
	}

	const columns = [
		{
			title: 'Name',
			dataIndex: 'podName',
			key: 'podName',
		},
		{
			title: 'Status',
			dataIndex: 'podStatus',
			key: 'podStatus',
			defaultSortOrder: 'ascend', // descend
			sorter: (a, b) => a.podStatus.length - b.podStatus.length,
			render: (_, { podStatus }) => <>{renderIcon(podStatus)}</>,
		},
		{
			title: 'Age',
			dataIndex: 'podStartTime',
			key: 'podStartTime',
			align: 'left',
			defaultSortOrder: 'descend',
			sorter: (a, b) => moment(a.podStartTime).unix() - moment(b.podStartTime).unix(),
			render: (_, { podStartTime }) => <>{calculateAge(podStartTime)}</>,
		},
	]

	return (
		<>
			{title ? (
				<div className={`${className} `} key={title}>
					<Collapse defaultActiveKey={['text-pipeline']} expandIconPosition='right'>
						<Panel header={<span className='coll-title'>{title}</span>} key={title}>
							<Table
								className='pod_detail_data'
								loading={isLoading}
								columns={columns}
								dataSource={data}
								bordered={false}
								pagination={false}
								rowClassName={(record) =>
									moment(record.podStartTime).isSameOrAfter(min2) && record.podStatus === 'Running'
										? 'active-row'
										: ''
								}
							/>
						</Panel>
					</Collapse>
				</div>
			) : (
				<Table
					className='pod_detail_data'
					loading={isLoading}
					columns={columns}
					dataSource={data}
					bordered={false}
					pagination={false}
				/>
			)}
		</>
	)
}

PODTable.propTypes = {
	data: PropTypes.array,
	title: PropTypes.string,
	className: PropTypes.string,
	isLoading: PropTypes.bool,
}

export default PODTable
