import { Navigate, Route, Routes } from 'react-router-dom'

import Dashboard from '../components/Dashboard'
import PageNotFound from '../components/PageNotFound'
import PegDashboard from '../components/PegDashboard'

const PrivateRouter = () => {
	return (
		<>
			<Routes>
				<Route path='/dashboard' element={<Dashboard />} />
				<Route path='/peg' element={<PegDashboard />} />

				<Route path='/' element={<Navigate to='/dashboard' replace />} />
				<Route path='*' element={<PageNotFound />} />
			</Routes>
		</>
	)
}

export default PrivateRouter
