import axios from 'axios'
import crypto from 'crypto-browserify'
import ROOT from '../client'
const Minio = require('minio')

export const getSearcData = (payload) => {
	return axios.post(`${ROOT}/api/get-search-res`, payload)
}

export const getAllSuggestions = (payload) => {
	return axios.post(`${ROOT}/api/get-all-values`, payload)
}

export const getMinioClient = (payload) => {
	const minioClient = new Minio.Client(payload)
	return minioClient
}

export const createMC = (envars) => {
	if (!envars.minioAccessKey || !envars.minioSecretKey) return

	const mak = getDecryptedToken(envars.minioAccessKey)
	const msk = getDecryptedToken(envars.minioSecretKey)
	const payload = {
		endPoint: envars?.minioEndPoint ? envars.minioEndPoint : '',
		port: envars?.minioPort ? Number(envars.minioPort) : 443,
		useSSL: true,
		accessKey: mak ? mak : '',
		secretKey: msk ? msk : '',
	}
	const minioClient = new Minio.Client(payload)
	return minioClient
}

const getDecryptedToken = (encryptedToken) => {
	try {
		const ivBuf = Buffer.from('phantom@security')
		const securityBuf = Buffer.from('phantom@securityphantom@security')
		let algo = 'aes-256-cbc'
		let decipher = crypto.createDecipheriv(algo, securityBuf, ivBuf)
		let decryptedToken = decipher.update(encryptedToken, 'hex', 'utf8')
		decryptedToken += decipher.final('utf8')
		return decryptedToken
	} catch (err) {
		console.log(err)
	}
}
