import { Card, Col, Layout, Row, Typography } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import './style.scss'
const { Link } = Typography
const { Header, Content } = Layout

const defaultTitle = `Unfortunately the page you’re looking for doesn’t exist (anymore) or there was an
error in the link you followed.`

const PageNotFound = (p) => {
	const { routeLocation } = useSelector((store) => store.storeProps)
	let btnLabel =
		routeLocation && routeLocation.indexOf('/') !== -1 ? routeLocation.replace('/', '') : ''
	btnLabel = btnLabel ? btnLabel : 'login'

	const bodyEl = document.getElementById('bodyEl')
	if (bodyEl) {
		bodyEl.classList.add('dashboardBg')
		bodyEl.classList.remove('loginBg')
	}

	return (
		<Layout>
			<Header className='header'>
				<Row>
					<Col span={3}>
						<Link href={routeLocation || '/'}>
							<img src='./fortress-logo-login.png' className='logo-dashboard' alt='FIQ' />
						</Link>
					</Col>
				</Row>
			</Header>
			<Content>
				<Card className='no-page-found-wrapper'>
					<div className='noPageFound'>
						<img src='./404-image.png' alt='404' />
						<p className='no-page-head'>{p?.title || defaultTitle}</p>
						<Link href={routeLocation || '/'} className='btnHome'>
							Go to <span style={{ textTransform: 'capitalize' }}>{btnLabel}</span>
						</Link>
					</div>
				</Card>
			</Content>
		</Layout>
	)
}

PageNotFound.propTypes = {
	title: PropTypes.string,
}

export default PageNotFound
