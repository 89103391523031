import { Button, Form, Input } from 'antd'
import axios from 'axios'
import crypto from 'crypto-browserify'
import PropTypes from 'prop-types'
import { useState } from 'react'

import ROOT from '../../client'
import CustomModal from '../Modal'
import customTost from '../Notification'
import './styles.scss'

const makeEncryptValues = (obj) => {
	const infoObj = JSON.stringify(obj)
	const ivBuf = Buffer.from('phantom@security')
	const securityBuf = Buffer.from('phantom@securityphantom@security')
	const algo = 'aes-256-cbc'
	const cipher = crypto.createCipheriv(algo, securityBuf, ivBuf)
	let cred = cipher.update(infoObj, 'utf8', 'hex')
	cred += cipher.final('hex')

	return cred
}

const ResetPassword = ({ isOpen, onClose, setIsLoading, logoutHandler }) => {
	const [localLoading, setLocalLoading] = useState(false)

	if (!isOpen) {
		return null
	}

	const onFinish = (values) => {
		try {
			if (!values.oldPassword) return

			setIsLoading(true)
			setLocalLoading(true)
			const cred = makeEncryptValues({
				oldPassword: values.oldPassword,
				newPassword: values.confirm,
			})
			const apiPath = `${ROOT}/api/password-reset`
			axios
				.post(apiPath, { cred })
				.then((resp) => {
					if (resp?.data?.statusCode === 200) {
						onClose()
						customTost({
							type: 'success',
							message: resp.data?.message
								? `${resp.data?.message}, please login again!`
								: 'Your password has been reset successfully',
						})

						setTimeout(() => {
							logoutHandler('/')
						}, 300)
					}
					setIsLoading(false)
					setLocalLoading(false)
				})
				.catch((error) => {
					setIsLoading(false)
					setLocalLoading(false)
					customTost({
						type: 'error',
						message: error?.response?.data?.message,
					})
				})
		} catch (error) {
			console.log('Error [ResetPassword]: ', error)
			setIsLoading(false)
			setLocalLoading(false)
		}
	}

	const handleModelClose = () => {
		if (!localLoading) {
			onClose()
		}
	}

	return (
		<>
			<CustomModal isOpen={isOpen} className='' onClose={handleModelClose} width={550}>
				<div className='reset-form-wrapper'>
					<div className='form-header'>Reset Password</div>
					<Form
						name='pass-reset-form'
						className='form-reset'
						labelCol={{ span: 8 }}
						wrapperCol={{ span: 16 }}
						onFinish={onFinish}
						requiredMark={false}
						autoComplete='off'
						disabled={localLoading}
						colon={false}
					>
						<Form.Item
							className='reset-field-container'
							label='Current Password'
							name='oldPassword'
							rules={[{ required: true, message: 'Required' }]}
							// hasFeedback
						>
							<Input.Password
								placeholder='Current Password'
								className='old-pass reset-input-field'
							/>
						</Form.Item>
						<Form.Item
							className='reset-field-container'
							name='password'
							label='New Password'
							rules={[
								{ required: true, message: 'Required' },
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue('oldPassword') !== value) {
											return Promise.resolve()
										}
										return Promise.reject(
											new Error("New password can't be same as current password."),
										)
									},
								}),
								() => ({
									validator(_, value) {
										const rej = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/
										if (rej.test(value)) {
											return Promise.resolve()
										}
										return Promise.reject(
											new Error(
												'Password must be at least 8 characters long and \
                        contain at least one uppercase letter, one lowercase letter,\
                        one digit and one special character.',
											),
										)
									},
								}),
							]}
						>
							<Input.Password placeholder='New Password' className='new-pass reset-input-field' />
						</Form.Item>
						<Form.Item
							className='reset-field-container'
							name='confirm'
							label='Confirm New Password'
							dependencies={['password']}
							rules={[
								{ required: true, message: 'Required' },
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue('password') === value) {
											return Promise.resolve()
										}
										return Promise.reject(new Error('Passwords do not match.'))
									},
								}),
							]}
						>
							<Input.Password
								placeholder='Confirm New Password'
								className='confirm-pass reset-input-field'
							/>
						</Form.Item>

						<div className='reset-btn-wrapper'>
							<Button
								disabled={localLoading}
								onClick={onClose}
								key='btnClose'
								className='close-btn'
							>
								Close
							</Button>

							<Button
								onClick={onFinish}
								htmlType='submit'
								type='primary'
								key='btndownload'
								disabled={localLoading}
							>
								Reset Password
							</Button>
						</div>
					</Form>
				</div>
			</CustomModal>
		</>
	)
}

ResetPassword.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	setIsLoading: PropTypes.func,
	logoutHandler: PropTypes.func,
}

export default ResetPassword
