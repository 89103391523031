import { SwapRightOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Form, Input, Row, Select, Switch } from 'antd'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveQueryStack } from '../../store/actions'
import './filter.scss'
import { getSuggestedValues } from './getSuggestedValues'

const { RangePicker } = DatePicker

const EditFilter = (props) => {
	const dispatch = useDispatch()
	const {
		indexValue,
		indexAlias,
		aliasFlag,
		observedAtDetail,
		imageDetail,
		mappingProps,
		queryStack,
	} = useSelector((store) => store.storeProps)
	const {
		editInitialVals,
		queryDetailArr,
		validation,
		setValidaton,
		handleCancel,
		editIndex,
		setIsValueMatch,
		isValueMatch,
	} = props
	const [form] = Form.useForm()
	const [options, setOptions] = useState([])

	useEffect(() => {
		if (editInitialVals) {
			form.setFieldsValue(editInitialVals)
		}
		// eslint-disable-next-line
	}, [editInitialVals])

	useEffect(() => {
		if (queryDetailArr?.length) {
			setOptions(queryDetailArr[0]?.values ? queryDetailArr[0].values : [])
		}
	}, [queryDetailArr])
	const tailLayout = {
		wrapperCol: { offset: 18, span: 6 },
	}

	const onFinish = (values) => {
		try {
			if (values) {
				let newQeryVal = ''
				let newFieldVal = ''
				let i = 0
				let isValid = false
				let isMatched = false
				if (queryStack?.length && queryDetailArr?.length) {
					if (
						queryDetailArr[0] &&
						queryDetailArr[0].operator !== 'BETWEEN' &&
						queryDetailArr[0].operator !== 'NOT BETWEEN'
					) {
						for (let key in values) {
							let keyType = ''
							imageDetail.forEach((val) => {
								if (val?.value === key) {
									keyType = val.type
								}
							})
							let keyVal = keyType !== 'boolean' ? values[key] : JSON.stringify(values[key])
							newFieldVal = keyVal
								? keyVal
								: keyVal === undefined || keyVal === 'true'
								? 'true'
								: 'false'
							newQeryVal = `${queryDetailArr[i]?.field} ${queryDetailArr[i]?.operator} ${
								keyVal ? keyVal : keyVal === undefined || keyVal === 'true' ? 'true' : 'false'
							}`
							i++
						}
						isValid = true
					} else {
						const rangeArr = []
						for (let key in values) {
							if (key && values[key]) {
								rangeArr.push(values[key])
							}
						}
						if (rangeArr.length) {
							if (
								queryDetailArr[0].type === 'date' &&
								rangeArr[0] &&
								rangeArr[0][0]?._d &&
								rangeArr[0][1]?._d
							) {
								const timezone = moment.tz.guess()
								// eslint-disable-next-line
								const st = moment(rangeArr[0][0]._d).format('YYYY-MM-DD[T]HH:mm:ss')
								const et = moment(rangeArr[0][1]._d).format('YYYY-MM-DD[T]HH:mm:ss')
								let startDate = moment.tz(st, timezone).format()
								let endDate = moment.tz(et, timezone).format()
								newFieldVal = `${startDate} AND ${endDate}`
								newQeryVal = `${queryDetailArr[0].field} BETWEEN ${startDate} AND ${endDate}`
								isValid = true
							} else if (rangeArr[0] && rangeArr[1]) {
								if (!isNaN(rangeArr[0]) && !isNaN(rangeArr[1])) {
									if (parseFloat(rangeArr[0]) < parseFloat(rangeArr[1])) {
										newFieldVal = `${rangeArr[0].trim()} AND ${rangeArr[1].trim()}`
										newQeryVal = `${queryDetailArr[0].field} ${
											queryDetailArr[0].operator
										} ${rangeArr[0].trim()} AND ${rangeArr[1].trim()}`
										setValidaton({ isValidate: true, message: '' })
										isValid = true
									} else {
										setValidaton({
											isValidate: false,
											message: 'End range should be greater than start range.',
										})
									}
								} else {
									setValidaton({ isValidate: false, message: 'Please enter numeric value.' })
								}
							} else {
								setValidaton({ isValidate: false, message: 'Required field.' })
							}
						} else {
							setValidaton({
								isValidate: false,
								message: `Required ${queryDetailArr[0].field} value.`,
							})
						}
					}

					// Matching the previous and new values
					if (queryDetailArr[0] && queryDetailArr[0].field && queryDetailArr[0].operator) {
						const formValKey = queryDetailArr[0].field.toLowerCase()
						const formValOperator = queryDetailArr[0].operator.toLowerCase()
						queryStack.forEach((val) => {
							if (val) {
								let existingField = val.field ? val.field.toLowerCase() : ''
								let existingVal = val.fieldValue ? val.fieldValue.toLowerCase().trim() : ''
								let newVal = newFieldVal ? newFieldVal.toLowerCase().trim() : ''
								let existingOperator = val.operator ? val.operator.toLowerCase() : ''
								if (
									existingVal === newVal &&
									existingField === formValKey &&
									existingOperator === formValOperator
								) {
									setIsValueMatch(true)
									isMatched = true
								}
							}
						})
					}
					// Updated the query stack with updated value
					if (queryStack.length && isValid && !isMatched) {
						const newStack = queryStack.map((val, index) => {
							if (index === editIndex) {
								return {
									query: newQeryVal.trim(),
									isChecked: val.isChecked,
									operand: val.operand,
									group: val.group,
									allowOperand: val.allowOperand,
									braces: val.braces,
									operator: val.operator,
									field: val.field,
									fieldValue: newFieldVal,
								}
							} else {
								return val
							}
						})
						dispatch(saveQueryStack(newStack))
						handleCancel()
						form.resetFields()
					}
				}
			}
		} catch (error) {
			setIsValueMatch(false)
			console.log(error)
		}
	}

	const onSearchChange = (value) => {
		try {
			if (value && queryDetailArr?.length && queryDetailArr[0]?.field) {
				getSuggestedValues(
					queryDetailArr[0].field,
					value,
					mappingProps,
					indexValue,
					indexAlias,
					aliasFlag,
					observedAtDetail,
					options,
				).then((opts) => {
					if (opts?.length) {
						setOptions(opts)
					}
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const onValueChange = () => {
		try {
			if (isValueMatch) {
				setIsValueMatch(false)
			}
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<div className='add-filters-section'>
			<Form form={form} name='editQueryform' onFinish={onFinish} className='editQuery'>
				{queryDetailArr.length > 0 && (
					<>
						{queryDetailArr.map((val, index) => {
							return (
								<div key={`queryform_${index}`}>
									{val &&
										val.type !== 'boolean' &&
										val.type !== 'long' &&
										val.type !== 'integer' &&
										val.operator !== 'BETWEEN' &&
										val.operator !== 'NOT BETWEEN' && (
											<>
												{val.values && val.values.length > 0 ? (
													<Form.Item
														label={`${val.field} ${val.operator}`}
														name={`${val.field}`}
														key={`${val.field}_${index}`}
														rules={[{ required: true, message: `Required ${val.field} value.` }]}
													>
														<Select
															style={{ width: '350px' }}
															onChange={onValueChange}
															onSearch={onSearchChange}
															placeholder='Select value'
															showSearch
															key={`select_${val.field}_${index}`}
														>
															{options.map((optVal) => {
																return (
																	<Select.Option key={optVal.label} value={`${optVal.label}`}>
																		{optVal.label}
																	</Select.Option>
																)
															})}
														</Select>
													</Form.Item>
												) : (
													<Form.Item
														className='customValidator'
														label={`${val.field} ${val.operator}`}
														name={`${val.field}`}
														key={`${val.field}_${index}`}
														rules={[
															{
																validator(_, value) {
																	if (value) {
																		value = value.trim()
																		if (value) {
																			return Promise.resolve()
																		} else {
																			return Promise.reject(
																				new Error(`Required ${val.field} value.`),
																			)
																		}
																	} else {
																		return Promise.reject(new Error(`Required ${val.field} value.`))
																	}
																},
															},
														]}
													>
														{val.field === 'ocr_text' ? (
															<Input.TextArea id='ocr_text_area' onChange={onValueChange} />
														) : (
															<Input
																onChange={onValueChange}
																key={`input_${val.field}_${index}`}
																placeholder='Enter value'
															/>
														)}
													</Form.Item>
												)}
											</>
										)}
									{val && val.type === 'date' && val.operator === 'BETWEEN' && (
										<Form.Item
											className='dateField'
											label={`${val.field} ${val.operator}`}
											name={`${val.field}`}
											key={`${val.field}_${index}`}
											rules={[{ required: true, message: `Required ${val.field} value.` }]}
										>
											<RangePicker
												showTime
												ranges={{
													Today: [moment(), moment()],
													'This Week': [moment().startOf('week'), moment().endOf('week')],
													'This Month': [moment().startOf('month'), moment().endOf('month')],
													'This Year': [moment().startOf('year'), moment().endOf('year')],
												}}
												onChange={onValueChange}
											/>
										</Form.Item>
									)}
									{val && (val.type === 'long' || val.type === 'integer') && (
										<>
											{val.operator !== 'BETWEEN' && val.operator !== 'NOT BETWEEN' ? (
												<Form.Item
													className='customValidator'
													label={`${val.field} ${val.operator}`}
													name={`${val.field}`}
													key={`${val.field}_${index}`}
													rules={[
														{
															validator(_, value) {
																if (value) {
																	value = value.trim()
																	if (!value) {
																		return Promise.reject(new Error(`Required ${val.field} value.`))
																	}
																	if (isNaN(value)) {
																		return Promise.reject(
																			new Error('Please enter numeric value only.'),
																		)
																	} else {
																		return Promise.resolve()
																	}
																} else {
																	return Promise.reject(new Error(`Required ${val.field} value.`))
																}
															},
														},
													]}
												>
													<Input
														maxLength={18}
														onChange={onValueChange}
														key={`input_${val.field}_${index}`}
														placeholder='Enter value'
													/>
												</Form.Item>
											) : (
												<div className='rangeGroup'>
													<Row>
														<label>
															{val.field} {val.operator}
														</label>
														<Col span={16}>
															<div
																className={`rangeFields ${
																	!validation.isValidate ? 'errorField' : ''
																}`}
															>
																<Row>
																	<Col span={11}>
																		<Form.Item
																			className='rangeField'
																			name='startNumberRange'
																			key={`${val.field}_lower_${index}`}
																		>
																			<Input
																				maxLength={18}
																				id='startNumberRange'
																				onChange={() => {
																					setValidaton({ isValidate: true, message: '' })
																					setIsValueMatch(false)
																				}}
																				placeholder='Start Range'
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={2} className='rangeIcon'>
																		<SwapRightOutlined />
																	</Col>
																	<Col span={11}>
																		<Form.Item
																			className='rangeField'
																			name='endNumberRange'
																			key={`${val.field}_higher_${index}`}
																		>
																			<Input
																				maxLength={18}
																				id='endNumberRange'
																				onChange={() => {
																					setValidaton({ isValidate: true, message: '' })
																					setIsValueMatch(false)
																				}}
																				placeholder='End Range'
																			/>
																		</Form.Item>
																	</Col>
																</Row>
															</div>
															<div className='errorMessage'>{validation.message}</div>
														</Col>
													</Row>
												</div>
											)}
										</>
									)}
									{val && val.type === 'boolean' && (
										<Form.Item
											label={`${val.field} ${val.operator}`}
											name={`${val.field}`}
											key={`${val.field}_${index}`}
											valuePropName='checked'
										>
											<Switch
												onChange={onValueChange}
												style={{ backgroundColor: '#F05742' }}
												checkedChildren='True'
												unCheckedChildren='False'
											/>
										</Form.Item>
									)}
									{isValueMatch && <div className='color-red'>This filter already exists.</div>}
								</div>
							)
						})}
					</>
				)}
				<Form.Item {...tailLayout} className='edit-filters-footer'>
					<Button type='primary' htmlType='submit'>
						Update
					</Button>
					<Button onClick={() => handleCancel('editFilter')}>Cancel</Button>
				</Form.Item>
			</Form>
		</div>
	)
}

EditFilter.propTypes = {
	editInitialVals: PropTypes.any,
	queryDetailArr: PropTypes.array,
	validation: PropTypes.object,
	setValidaton: PropTypes.func,
	handleCancel: PropTypes.func,
	editIndex: PropTypes.number,
	setIsValueMatch: PropTypes.func,
	isValueMatch: PropTypes.bool,
}

export default EditFilter
