import { CopyOutlined, SyncOutlined } from '@ant-design/icons'
import 'animate.css/animate.css'
import { Button, Collapse, Tooltip } from 'antd'
import axios from 'axios'
import { orderBy } from 'lodash'
import moment from 'moment-timezone'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import ROOT from '../../client'
import { createMC } from '../../services'
import '../../toastr.min.css'
import customTost from '../Notification'
import PODStatus from '../PODStatus'
import SettingsTab from '../SettingsTab'
import TopHeader from '../TopHeader'
import './style.scss'

const { Panel } = Collapse

const PegDashboard = () => {
	const bodyEl = document.getElementById('bodyEl')
	if (bodyEl) {
		bodyEl.classList.add('dashboardBg')
		bodyEl.classList.remove('loginBg')
	}
	const { adminUserFlag, envars } = useSelector((store) => store.storeProps)
	const [initialValues, setInitialValues] = useState({})
	const [pegEnvars, setPegEnvars] = useState([])
	const [pods, setPods] = useState({})
	const [lastUpdateData, setLastUpdateData] = useState('')
	const intervalRef = useRef(null)
	const { deployEnv } = envars
	const hasInitalValues = Object.keys(initialValues).length === 0
	const [isProxySelected, setProxySelected] = useState(false)
	const [isPodDataLoading, setPodDataLoading] = useState(false)

	useEffect(() => {
		try {
			// create cron job
			axios
				.get(`${ROOT}/api/create-cronjob`)
				.then(() => {
					getEnars()
				})
				.catch((err) => {
					getEnars()
					console.log('Error while creating cron job: ', err)
				})

			// Fetch PODs detail
			getPODsData()

			// Listener triggered when POD Data is updated
			window.addEventListener('POD_UPDATED', () => {
				const podLastUpdated = localStorage.getItem('podLastUpdated')
				let lastUpdatedDate = moment(podLastUpdated).format('MMM DD, YYYY HH:mm A')
				setLastUpdateData(lastUpdatedDate)

				intervalRef.current = setInterval(() => {
					const podLastUpdated = localStorage.getItem('podLastUpdated')
					let lastUpdatedDate = moment(podLastUpdated).format('MMM DD, YYYY HH:mm A')
					let timeDiff = moment(podLastUpdated).fromNow()
					setLastUpdateData(`${lastUpdatedDate} (${timeDiff})`)
				}, 1000 * 60)
			})

			return () => {
				clearInterval(intervalRef.current)
				localStorage.removeItem('podLastUpdated')
			}
		} catch (err) {
			console.log('Error [/api/get-deployment-envars]: ', err)
		}

		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		const interval = setInterval(async () => {
			getPODsData()
		}, 180000)

		return () => clearInterval(interval)
	}, [])

	const getEnars = () => {
		try {
			// Fetch and set Forwarding form values
			axios.get(`${ROOT}/api/get-deployment-envars`).then((res) => {
				let values = res?.data?.envData
				if (res?.data?.envData?.length) {
					values = res.data.envData.reduce((modArr, item) => {
						modArr[item.name] = item.value ? item.value : ''
						return modArr
					}, {})
				}
				const UTC_START_DATE = moment(values.UTC_START_DATE_FOR_FORWARDING, 'YYYY-MM-DD HH:mm:ss')
				const UTC_END_DATE = moment(values.UTC_END_DATE_FOR_FORWARDING, 'YYYY-MM-DD HH:mm:ss')
				values.external_fluentd_port =
					values.external_fluentd_port === '' ? '443' : values.external_fluentd_port
				values.UTC_START_DATE_FOR_FORWARDING = UTC_START_DATE
				values.UTC_END_DATE_FOR_FORWARDING = UTC_END_DATE
				values.symspell__edit_distance = values.symspell__edit_distance === '1' ? 'true' : 'false'

				if (values.has_fluentd_proxy === 'true') {
					setProxySelected(true)
				}
				setPegEnvars(res?.data?.envData)
				setInitialValues(values)
			})
		} catch (err) {
			console.log('Error [/api/get-deployment-envars]: ', err)
		}
	}

	const [exportState, setExportState] = useState(false)
	const [logsState, setLogsState] = useState(false)
	const [exportUrl, setExportUrl] = useState('')

	const handleExportClick = () => {
		try {
			setExportState(true)
			axios
				.get(`${ROOT}/api/get-pod-logs`)
				.then((res) => {
					if (res) {
						const minioClient = createMC(envars)
						minioClient
							.statObject('system-files', 'logs.zip')
							.then((metaData) => {
								if (metaData) {
									minioClient
										.presignedGetObject('system-files', 'logs.zip')
										.then((url) => {
											if (url) {
												setExportUrl(url)
												setLogsState(true)
												setExportState(false)
											}
										})
										.catch((error) => {
											console.log('Error: ', error)
											setExportState(false)
										})
								} else {
									setExportState(false)
								}
							})
							.catch((err) => {
								customTost({
									type: 'error',
									message:
										err.code === 'NotFound'
											? 'An error occurred while generating logs. Please contact administrator.'
											: 'Something went wrong. Please try after sometime.',
								})
								setExportState(false)
							})
					}
				})
				.catch((err) => {
					console.log('Error: ', err)
					setExportState(false)
				})
		} catch (err) {
			console.log('Error [/api/get-pod-logs]: ', err)
			setExportState(false)
		}
	}
	const downloadLogs = () => {
		setLogsState(false)
	}

	// POD Status Tab - Fetch POD Data
	const getPODsData = (e) => {
		e && e.stopPropagation()
		try {
			// if getPodsData is already running then skip it
			if (isPodDataLoading) return

			setPodDataLoading(true)
			axios
				.get(`${ROOT}/api/get-pods-detail`)
				.then((res) => {
					if (res) {
						localStorage.setItem('podLastUpdated', new Date())
						dispatchEvent(new Event('POD_UPDATED'))
						const podsData = res?.data?.detail
						let sortingArr = ['default', 'text-pipeline', 'minio', 'k-lite']
						let sortedCollection = orderBy(
							podsData,
							function (item) {
								return sortingArr.indexOf(item.nameSpace)
							},
							'desc',
						)

						// Grouping response data based on namespaces
						const groupByNameSpace = sortedCollection.reduce((group, pod) => {
							const { nameSpace, podName } = pod
							group[nameSpace] = group[nameSpace] ?? []
							pod.key = podName
							group[nameSpace].push(pod)
							return group
						}, {})
						setPods(groupByNameSpace)
						setPodDataLoading(false)
					}
				})
				.catch((err) => {
					console.log('Error: ', err)
					setPodDataLoading(false)
				})
		} catch (err) {
			setPodDataLoading(false)
			console.log('Error [/api/get-pods-detail]: ', err)
		}
	}

	// Render POD Header
	const renderPODHEader = () => {
		return (
			<div className='pod_header'>
				<div>POD Status</div>
				{lastUpdateData && (
					<div style={{ display: 'flex' }}>
						<div className='pod_header_update'>Last Updated {lastUpdateData}</div>
						<Tooltip placement='bottom' arrowPointAtCenter title='Refresh'>
							<Button
								size='small'
								className='btnRefresh'
								type='primary'
								icon={<SyncOutlined />}
								disabled={isPodDataLoading}
								onClick={(e) => getPODsData(e)}
							/>
						</Tooltip>
					</div>
				)}
			</div>
		)
	}

	return (
		<>
			{adminUserFlag && deployEnv !== 'aws' ? (
				<>
					<TopHeader bucket={false} setIsLoading={() => {}} />
					<div className='ant-form-vertical'>
						<div className='peg_wrapper'>
							<div className='peg_wrapper_header'>
								<div>Redaction & Forwarding Panel</div>
								<div>
									{!exportState ? (
										<>
											{logsState ? (
												<a
													className='btnExport downloadLogs'
													onClick={downloadLogs}
													href={exportUrl}
												>
													<CopyOutlined /> Download Logs
												</a>
											) : (
												<Button
													className='btnExport'
													type='primary'
													size='middle'
													onClick={handleExportClick}
													icon={<CopyOutlined />}
												>
													Export Logs
												</Button>
											)}
										</>
									) : (
										<Button
											className='btnExport'
											type='primary'
											size='middle'
											loading={exportState}
										>
											Generating Logs...
										</Button>
									)}
								</div>
							</div>
							<div className='peg_wrapper_accordion'>
								<Collapse defaultActiveKey={['1']} expandIconPosition='right'>
									<Panel header='General Settings' key='1'>
										<SettingsTab
											isDisabled={hasInitalValues}
											isProxySelected={isProxySelected}
											setProxySelected={setProxySelected}
											initialValues={initialValues}
											pegEnvars={pegEnvars}
											setPegEnvars={setPegEnvars}
										/>
									</Panel>
									<Panel header={renderPODHEader()} key='2' className='pod_panel'>
										<PODStatus pods={pods} isLoading={isPodDataLoading} />
									</Panel>
								</Collapse>
							</div>
						</div>
					</div>
				</>
			) : (
				<Navigate to='/404' />
			)}
		</>
	)
}

export default PegDashboard
