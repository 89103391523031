import { EyeOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input, Typography } from 'antd'
import axios from 'axios'
import crypto from 'crypto-browserify'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ROOT from '../../client'
import {
	saveAdminUserFlag,
	saveBuckets,
	saveDate,
	saveEnvars,
	saveGraphVisibility,
	saveImageKeys,
	saveIndexValue,
	saveMappingKey,
	saveMaskedBucket,
	saveMaskedFlag,
	saveName,
	saveQueryStack,
	saveRouteLocation,
	saveSearchData,
	saveSelectedBucket,
	saveSessionFlag,
	saveTokenFlag,
	setDeleteMode,
	setIsNoRecordsAvailable,
	updateConfigureFields,
} from '../../store/actions'
import Footer from '../Footer'
import customTost from '../Notification'
import './Login.scss'
const { Link } = Typography

const LogIn = () => {
	const { sessionFlag, isDeleteModeOn, noRecordsAvailable } = useSelector(
		(store) => store.storeProps,
	)
	const dispatch = useDispatch()
	const location = useLocation()
	const navigate = useNavigate()
	const pathName = location?.pathname ? location?.pathname : ''
	const bodyEl = document.getElementById('bodyEl')
	if (bodyEl) {
		bodyEl.classList.remove('dashboardBg')
		bodyEl.classList.add('loginBg')
	}
	const [loading, setLoading] = useState(false)
	const [passwordVisible, setPasswordVisible] = useState(false)
	const [spaceWarning, setSpaceWarning] = useState(false)
	const passwordRef = useRef(null)

	useEffect(() => {
		if (!sessionFlag) {
			dispatch(saveAdminUserFlag(false))
			dispatch(saveRouteLocation(pathName))
			dispatch(saveBuckets(''))
			dispatch(saveSelectedBucket(''))
			dispatch(saveMaskedBucket(''))
			dispatch(saveQueryStack([]))
			dispatch(saveGraphVisibility(false))
			dispatch(saveSearchData([]))
			dispatch(saveImageKeys([]))
			dispatch(saveMaskedFlag(false))
			dispatch(
				saveDate({
					dateRange: '',
					startDate: '',
					endDate: '',
				}),
			)
		}
		try {
			axios
				.get(`${ROOT}/api/get-env`)
				.then((resp) => {
					if (resp && resp.data && resp.data.statusCode === 200 && resp.data.envData) {
						let envarData = resp.data.envData
						const essl = envarData.elasticSsl && envarData.elasticSsl === true ? true : false
						const ussl = envarData.useSsl ? true : false
						const mPort = envarData.minioPort ? Number(envarData.minioPort) : ''
						envarData = {
							...envarData,
							elasticSsl: essl,
							useSsl: ussl,
							minioPort: mPort,
							elasticType:
								envarData.elasticType && envarData.elasticType !== 'OPENDISTRO'
									? envarData.elasticType.toLowerCase()
									: envarData.elasticType,
						}
						dispatch(saveMappingKey(envarData.imageKey))
						dispatch(
							saveIndexValue({
								index: envarData?.elasticIndex ? envarData.elasticIndex : 'event_logs',
								alias: '',
								aliasFlag: false,
								allIndexes: '',
								aliasIndexesArr: [],
							}),
						)
						dispatch(saveEnvars(envarData))
					}
				})
				.catch((error) => {
					console.log(error)
					if (error?.response?.data?.message) {
						customTost({
							type: 'error',
							message: error.response.data.message,
						})
					}
				})
			axios
				.get(`${ROOT}/api/check-disk-space`)
				.then((res) => {
					if (res?.data?.diskSpace) {
						setSpaceWarning(res.data.diskSpace >= 80 ? true : false)
					}
				})
				.catch((error) => {
					console.log(error)
					if (error?.response?.data?.message) {
						customTost({
							type: 'error',
							message: error.response.data.message,
						})
					}
				})
		} catch (error) {
			console.log('Error [Login UseEffect]:', error)
		}
		// eslint-disable-next-line
	}, [])

	const retryCheckToken = async () => {
		try {
			const apiPath = `${ROOT}/api/check-token`
			const resp = await axios.get(apiPath)

			if (resp.data.verified === true) {
				setLoading(false)
				dispatch(saveSessionFlag(true))
				navigate('/dashboard')
				return true
			} else {
				setLoading(false)
				return false
				// await retryCheckToken()
			}
		} catch (error) {
			console.log('Error [Retry Check token]: ', error)
			return false
		}
	}

	const afterLoginSuccess = async () => {
		const tokenVerified = await retryCheckToken()
		if (!tokenVerified) return
		dispatch(setIsNoRecordsAvailable({ noRecordsAvailable: true }))
		dispatch(saveTokenFlag(true))
		dispatch(updateConfigureFields([{ label: 'title' }]))

		// if no records set delete mode false
		// CASE: if logged in with deletemode on
		if (!noRecordsAvailable && isDeleteModeOn) dispatch(setDeleteMode({ isDeleteModeOn: false }))
	}

	const onFinish = async (values) => {
		try {
			if (values.username && values.password) {
				setLoading(true)
				const apiPath = `${ROOT}/api/login`
				dispatch(saveName(values.username))
				let infoObj = {
					username: values.username,
					password: values.password,
				}
				infoObj = JSON.stringify(infoObj)
				const ivBuf = Buffer.from('phantom@security')
				const securityBuf = Buffer.from('phantom@securityphantom@security')
				let algo = 'aes-256-cbc'
				let cipher = crypto.createCipheriv(algo, securityBuf, ivBuf)
				let cred = cipher.update(infoObj, 'utf8', 'hex')
				cred += cipher.final('hex')
				axios
					.post(apiPath, { cred })
					.then(async (resp) => {
						// setLoading(false)
						if (resp?.data?.statusCode === 200) {
							await afterLoginSuccess()
							if (resp?.data?.allAccess) dispatch(saveAdminUserFlag(true))
						} else {
							setLoading(false)
						}
					})
					.catch((error) => {
						setLoading(false)
						if (error?.response?.data?.message) {
							customTost({
								type: 'error',
								message:
									error.response.data.message === 'Please contact administrator for access.'
										? 'Invalid Credentials, Please try again.'
										: error.response.data.message,
							})
						}
						return
					})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const handleEnter = (e) => {
		try {
			passwordRef.current.focus()
			e.preventDefault()
		} catch (error) {
			console.log(error)
		}
	}

	const handleMouseDown = (e) => {
		try {
			setPasswordVisible(true)
			passwordRef.current.focus()
			e.preventDefault()
		} catch (error) {
			console.log(error)
		}
	}

	const handleMouseUp = (e) => {
		try {
			setPasswordVisible(false)
			passwordRef.current.focus()
			e.preventDefault()
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<>
			<div className='flex-container-column'>
				<div className='logo-login'>
					<Link href='/'>
						<img src='./fortress-logo-login.png' alt='FIQ' />
					</Link>
				</div>
				<div className='flex-container-row'>
					<div className='heading'>
						<p className='head'>Unlock the limitless potential of the global workforce</p>
						<p className='sub-head'>
							FortressIQ uses AI and Computer Vision to capture and discover all relevant process
							data in just weeks.
						</p>
					</div>
					<div className='loginWrapper'>
						{spaceWarning && (
							<div className='disk-space-warning'>
								<img src='./warning-icon.svg' alt='warning' className='warningIcon' />
								<p className='disk-text'>
									Disk space on this VM is running out. Kindly delete some data to avoid unexpected
									errors.
								</p>
								<img
									src='./close-icon.svg'
									alt='warning'
									className='close-icon'
									onClick={() => setSpaceWarning(false)}
								/>
							</div>
						)}
						<div className='loginForm'>
							<div className='loginHead'>
								<h1>
									<strong>Please login to Fortress IQ</strong>
								</h1>
							</div>
							<Form
								className='form-login'
								onFinish={onFinish}
								requiredMark={false}
								layout='vertical'
							>
								<Form.Item
									name='username'
									rules={[
										{
											validator(_, value) {
												if (value) {
													const specialCharRegex = new RegExp(/^[^\\//#?%:$ ]+$/)
													if (!specialCharRegex.test(value)) {
														return Promise.reject(
															new Error(
																'Username must contain only letters, numbers, special characters as per the elastic constraints. Spaces are not allowed.',
															),
														)
													} else {
														return Promise.resolve()
													}
												} else {
													return Promise.reject(new Error('Please enter your username.'))
												}
											},
										},
									]}
									label='User Name'
									className='login-label login-input'
								>
									<Input
										className='username'
										suffix={
											<UserOutlined
												className='site-form-item-icon'
												style={{ fontSize: '18px', color: '#5e5e5e' }}
											/>
										}
										placeholder='Enter Name'
										onPressEnter={handleEnter}
										autoComplete='username'
									/>
								</Form.Item>
								<Form.Item
									name='password'
									rules={[
										{
											required: true,
											message: 'Please enter your password',
										},
									]}
									label='Password'
									className='login-label login-input'
								>
									<Input
										ref={passwordRef}
										suffix={
											<EyeOutlined
												onMouseDown={(e) => handleMouseDown(e)}
												onMouseUp={(e) => handleMouseUp(e)}
												className='eyeIcon site-form-item-icon'
												style={{ cursor: 'pointer', fontSize: '18px', color: '#5e5e5e' }}
											/>
										}
										type={passwordVisible ? 'text' : 'password'}
										className='password'
										placeholder='Enter Password'
										autoComplete='current-password'
									/>
								</Form.Item>
								<Form.Item>
									<Button
										className='btnLogin'
										type='primary'
										htmlType='submit'
										block
										size='large'
										loading={loading}
									>
										Login
									</Button>
								</Form.Item>
							</Form>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</>
	)
}

export default LogIn
