import { CheckCircleFilled, DownloadOutlined, InfoCircleFilled } from '@ant-design/icons'
import { Button, Form, Spin, Upload } from 'antd'
import axios from 'axios'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import ROOT from '../../client'
import { createMC } from '../../services'
import AddWords from '../AddWords'
import * as helper from '../helper'
import CustomModal from '../Modal'
import customTost from '../Notification'
import './style.scss'

const AllowBlockItem = (props) => {
	const { source, isUploading, setIsuploading, tooltipText } = props
	const { envars } = useSelector((store) => store.storeProps)
	const [uploadedFile, setUploadedFile] = useState()
	const [sourceCall, setSourceCall] = useState('Upload')
	const [isLoading, setLoading] = useState(false)
	const [showSuccessModal, setSuccessModal] = useState(false)
	const [list, setList] = useState([])
	const [listText, setListText] = useState('')
	const [duplicateItems, setDuplicateItems] = useState([])

	const downloadTrigger = useRef(null)

	const fileName = source === 'Pass' ? 'pass-list.txt' : 'block-list.txt'
	const minioClient = createMC(envars)

	useEffect(() => {
		getFile(fileName)
		// eslint-disable-next-line
	}, [])

	// Check if file is getting uploaded for Allow list or Block List
	const updateUploadState = (state) => {
		let newState
		if (source === 'Pass') {
			newState = { Pass: state, Block: false }
		} else {
			newState = { Pass: false, Block: state }
		}

		setIsuploading(newState)
	}

	// Validate if session is active before uploading file to Minio
	const validateSessionAndUpload = async (streamFile, isListEmpty) => {
		try {
			const apiPath = `${ROOT}/api/get-all-indices`
			axios.get(apiPath).then((resp) => {
				if (resp && resp?.data) {
					uploadToMinio(streamFile, isListEmpty)
				}
			})
		} catch (error) {
			customTost({
				type: 'error',
				message: 'Something went wrong.',
			})
		}
	}

	const uploadToMinio = (stream, isListEmpty) => {
		// Upload file to minio
		if (minioClient) {
			/* istanbul ignore next */
			minioClient.putObject('dictionaries', fileName, stream, function (err) {
				if (err) {
					updateUploadState(false)
					customTost({
						type: 'error',
						message: 'Something went wrong.',
					})
				} else {
					updateUploadState(false)
					// Update on UI, the latest file uploaded
					getFile(fileName)
					setLoading(false)
					setSuccessModal(true)
					if (!isListEmpty) {
						setList([])
						setListText('')
						setDuplicateItems([])
					}
				}
			})
		}
	}

	// Fetch Allow/Block List file from minio
	/* istanbul ignore next */
	const getFile = () => {
		try {
			minioClient
				.statObject('dictionaries', fileName)
				.then((metaData) => {
					if (metaData) {
						minioClient
							.presignedGetObject('dictionaries', fileName, 604800, {
								'response-content-disposition': 'attachment',
							})
							.then((res) => {
								if (res) {
									setUploadedFile(res)
								}
							})
							.catch((error) => {
								console.log('error: ', error)
							})
					}
				})
				.catch((err) => {
					console.log('err: ', err)
					customTost({
						type: 'error',
						message:
							err.code === 'NotFound'
								? `${source} List File does not exist yet. Please upload a new file.`
								: 'Something went wrong. Please try after sometime.',
					})
				})
		} catch (err) {
			console.log(err)
		}
	}

	// Add Words - Fetch file from minio and add new words to the existing file
	/* istanbul ignore next */
	const getAndUpdateFile = (wordsWithComma, calledFrom) => {
		try {
			setLoading(true)
			if (wordsWithComma) {
				let wordsArr = wordsWithComma.split('","')
				// Remove double qoutes from start and end of string
				wordsArr = wordsArr.map((ele) => ele.replace(/(^"|"$)/g, ''))
				wordsArr = wordsArr.map((n) => n?.split(' ')[0])
				wordsArr = wordsArr.map((i) => (i.endsWith(' 1') ? i : i + ' 1'))
				setSourceCall(calledFrom)
				const chunks = []
				let data
				if (minioClient) {
					minioClient.getObject('dictionaries', fileName, function (err, dataStream) {
						if (err) {
							return appendData([], wordsArr)
						}
						dataStream.on('data', function (chunk) {
							chunks.push(Buffer.from(chunk))
						})
						dataStream.on('end', function () {
							data = Buffer.concat(chunks).toString('utf-8')
							let arrOfData = data?.split('\n')
							let lastElement = arrOfData[arrOfData.length - 1]
							// Remove last empty element
							!lastElement && arrOfData.splice(arrOfData.length - 1, 1)
							appendData(arrOfData, wordsArr)
						})
						dataStream.on('error', function (err) {
							console.log(err)
						})
					})
				}
			} else {
				setLoading(false)
				customTost({
					type: 'error',
					message: 'Please add at least one word',
				})
			}
		} catch (err) {
			console.log('Error: [AllowBlockItem]: getAndUpdateFile', err)
		}
	}

	// Append new words to existing list
	/* istanbul ignore next */
	const appendData = (existingArr, newArr) => {
		let combinedArr = [...existingArr, ...newArr]
		combinedArr = combinedArr.map((ele) => `"${ele.toLowerCase()}"`)
		combinedArr = helper.removeDuplicates(combinedArr)
		let commaSeparated = combinedArr.toString()
		createFile(commaSeparated)
	}

	// Create a new file to be uploaded to minio
	const createFile = (wordsWithComma, isListEmpty = false) => {
		let newlineWords = wordsWithComma.replace(/","/g, '\n')
		newlineWords = newlineWords.replace(/(^"|"$)/g, '')
		validateSessionAndUpload(newlineWords, isListEmpty)
	}

	const validRequest = ({ onSuccess }) => {
		onSuccess(() => {
			console.log('onsucess')
		})
	}

	// Upload props
	const uploadProps = {
		name: 'file',
		accept: '.txt',
		maxCount: 1,
		customRequest: validRequest,
		beforeUpload(file) {
			try {
				setSourceCall('Upload')
				if (file.type !== 'text/plain') {
					customTost({
						type: 'error',
						message: 'Please upload text file only.',
					})
					return false
				}

				let reader = new FileReader()
				let wordsList = []
				let combinedArr = []
				reader.onload = function (event) {
					// setTempFileName(file?.name);
					updateUploadState(true)
					wordsList = event?.target?.result?.split(/\r?\n/)
					wordsList = wordsList.map((n) => n?.trim()?.toLowerCase()?.split(' ')[0])
					// Check for empty words
					wordsList = wordsList.filter((n) => n)
					let lastElement = wordsList[wordsList.length - 1]
					!lastElement && wordsList.splice(wordsList.length - 1, 1)
					wordsList = wordsList.map((i) => (i.endsWith(' 1') ? i : i + ' 1'))
					// Remove duplicates
					combinedArr = helper.removeDuplicates(wordsList)
					// Enclose element in double quotes
					combinedArr = combinedArr.map((ele) => `"${ele}"`)

					let commaSeparated = combinedArr.toString()
					createFile(commaSeparated, true)
				}

				reader.onerror = function () {
					updateUploadState(false)
				}

				reader.readAsText(file)
			} catch (error) {
				updateUploadState(false)
				console.log(error)
			}
		},
	}

	const downloadUpdatedList = () => {
		if (uploadedFile) {
			downloadTrigger.current.click()
			setSuccessModal(false)
		}
	}

	return (
		<>
			<div className={`${source}_wrapper`}>
				<div className='upload_wrapper'>
					<Form.Item
						style={{ fontWeight: '700' }}
						label={<>{source} List</>}
						tooltip={{ title: tooltipText, icon: <InfoCircleFilled /> }}
					>
						<Upload id={`uploadField_${source}`} {...uploadProps} className='upload_wrapper'>
							<Button className='upload-btn'>
								{isUploading[source] ? (
									<>
										<span style={{ marginRight: '80px' }}>Uploading...</span>
										<Spin size='small' />
									</>
								) : (
									'Upload new list document'
								)}
							</Button>
						</Upload>
					</Form.Item>
					{uploadedFile && (
						<>
							<DownloadOutlined className='file_icon' />
							<a download ref={downloadTrigger} className='uploaded_file' href={uploadedFile}>
								{fileName}
							</a>
						</>
					)}
				</div>
				<AddWords
					source={source}
					getAndUpdateFile={getAndUpdateFile}
					loading={isLoading}
					list={list}
					setList={setList}
					listText={listText}
					setListText={setListText}
					duplicateItems={duplicateItems}
					setDuplicateItems={setDuplicateItems}
				/>
			</div>
			<CustomModal
				isOpen={showSuccessModal}
				className='successModal modal_wrapper'
				onClose={() => setSuccessModal(false)}
				footer={[
					<Button
						onClick={() => setSuccessModal(false)}
						type='primary'
						key='btnClose'
						id='btnClose'
					>
						CLOSE
					</Button>,
					<Button
						className={`${sourceCall === 'Upload' ? 'hideBtn' : ''}`}
						onClick={downloadUpdatedList}
						type='primary'
						key='btndownload'
					>
						DOWNLOAD UPDATED LIST
					</Button>,
				]}
			>
				<div className='success_modal'>
					<CheckCircleFilled style={{ color: '#03A803', fontSize: '50px' }} />
					<div className='success_modal_header'>Updated Successfully</div>
					<div className='success_modal_sub'>
						{source} List updated successfully, duplicate words (if any) have been removed to avoid
						errors.
					</div>
				</div>
			</CustomModal>
		</>
	)
}

AllowBlockItem.propTypes = {
	source: PropTypes.string,
	isUploading: PropTypes.object,
	setIsuploading: PropTypes.func,
	tooltipText: PropTypes.string,
}

export default AllowBlockItem
