import { PlusOutlined } from '@ant-design/icons'
import dateMath from '@elastic/datemath'
import {
	EuiButton,
	EuiFieldText,
	EuiFormControlLayoutDelimited,
	EuiSuperDatePicker,
} from '@elastic/eui'
import { Button, Col, Row, Select, Switch } from 'antd'
import moment from 'moment-timezone'
import PropsTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllowedFiltersForDelete } from '../../helper'
import { saveQueryStack } from '../../store/actions'
import '../DateTimePicker/dateTimePicker.scss'
import { generateConfig } from '../Search/config'
import './filter.scss'
import { getSuggestedValues } from './getSuggestedValues'
const { Option } = Select

const AddFilter = (props) => {
	const dispatch = useDispatch()
	const {
		indexValue,
		indexAlias,
		aliasFlag,
		observedAtDetail,
		imageDetail,
		mappingProps,
		queryStack,
		isDeleteModeOn,
	} = useSelector((store) => store.storeProps)
	let {
		setFilterValue,
		filterValue,
		lowerRange,
		higherRange,
		setHigherRange,
		setLowerRange,
		setAddFilter,
		clearFilter,
	} = props
	const [filterField, setFilterField] = useState([])
	const [operand, setOperand] = useState([])
	const [filterOptions, setFilterOptions] = useState([])
	const [showDateText, setShowDateText] = useState(false)
	const [showBooleanMsg, setShowBooleanMsg] = useState(false)
	const [showFilterSelect, setShowFilterSelect] = useState(false)
	const [disabled, setDisabled] = useState(true)
	const [nonNumeric, setNonNumeric] = useState(false)
	const [rangeError, setRangeError] = useState(false)
	const [dateError, setDateError] = useState(false)
	const [start, setStart] = useState('now-15m')
	const [end, setEnd] = useState('now')
	const [operandOptions, setOperandOptions] = useState([])
	const timezone = moment.tz.guess()

	useEffect(() => {
		try {
			let error = false
			if (mappingProps[filterField]?.type === 'long') {
				if (isNaN(lowerRange) || isNaN(higherRange)) {
					setNonNumeric(true)
					setRangeError(false)
					setDisabled(true)
					error = true
				} else {
					setNonNumeric(false)
					setDisabled(false)
				}
				if (
					parseFloat(lowerRange) >= parseFloat(higherRange) &&
					!isNaN(lowerRange) &&
					!isNaN(higherRange)
				) {
					setRangeError(true)
					setDisabled(true)
					error = true
				} else {
					setRangeError(false)
					setDisabled(false)
				}
			}
			if (mappingProps[filterField]?.type === 'date' && lowerRange > higherRange) {
				setDateError(true)
				error = true
				setDisabled(true)
			} else {
				setDateError(false)
				setDisabled(false)
			}
			if (lowerRange === '' || higherRange === '' || error) {
				setDisabled(true)
			}
		} catch (error) {
			console.log(error)
		}
		// eslint-disable-next-line
	}, [lowerRange, higherRange])

	// to reset filters each time date range is changed for bulk delete mode
	useEffect(() => {
		setFilterField([])
		setOperand([])
		setFilterOptions([])
	}, [clearFilter])

	const closePopover3 = () => setAddFilter(false)
	const onSelectFilterField = (selectedOptions) => {
		try {
			if (selectedOptions.length) {
				let operandOpts = [
					{
						value: 'is',
						label: 'is',
					},
					{
						value: 'is not',
						label: 'is not',
					},
				]
				setFilterField(selectedOptions)
				setDisabled(true)
				setOperand([])
				setLowerRange('')
				setHigherRange('')
				setRangeError(false)
				setDateError(false)
				setNonNumeric(false)
				setFilterMatched(false)
				if (mappingProps[selectedOptions].type !== 'date') {
					setShowDateText(false)
				}
				if (mappingProps[selectedOptions].type !== 'boolean') {
					setShowBooleanMsg(false)
				}

				operandOpts = operandOpts.filter(
					(e) => e.value !== 'is between' && e.value !== 'is not between',
				)
				if (
					mappingProps[selectedOptions].type === 'long' ||
					mappingProps[selectedOptions].type === 'integer'
				) {
					operandOpts.push(
						{
							value: 'is between',
							label: 'is between',
						},
						{
							value: 'is not between',
							label: 'is not between',
						},
					)
				}

				if (mappingProps[selectedOptions].type === 'date') {
					operandOpts = []
					operandOpts.push({
						value: 'is between',
						label: 'is between',
					})
				}
				if (mappingProps[selectedOptions].isLikeOperator) {
					operandOpts = []
					operandOpts.push(
						{
							value: 'LIKE',
							label: 'Like',
						},
						{
							value: 'NOT LIKE',
							label: 'Not like',
						},
					)
				}
				setOperandOptions(operandOpts)
			}
		} catch (error) {
			console.log(error)
		}
	}
	const [disableValueSelector, setDisableValueSelector] = useState(false)
	const [keyField, setKeyField] = useState('')
	const onSelectOperand = (e) => {
		try {
			setOperand(e)
			setLowerRange('')
			setHigherRange('')
			setFilterMatched(false)
			const fieldType = mappingProps[filterField].type
			if (
				fieldType === 'keyword' ||
				(mappingProps[filterField].fields &&
					mappingProps[filterField].fields.keyword.type === 'keyword')
			) {
				setShowFilterSelect(true)
			} else {
				setShowFilterSelect(false)
			}
			if (fieldType === 'date') {
				setShowDateText(true)
				setDisabled(false)
				const startInit = moment(dateMath.parse(start)).format('YYYY-MM-DDTHH:mm:ss')
				const endInit = moment(dateMath.parse(end)).format('YYYY-MM-DDTHH:mm:ss')
				const st = moment.tz(startInit, timezone).format()
				const et = moment.tz(endInit, timezone).format()
				setFilterValue(`${st} AND ${et}`)
			} else {
				setShowDateText(false)
			}
			if (fieldType === 'boolean') {
				setFilterValue('false')
				setShowBooleanMsg(true)
				setDisabled(false)
			} else {
				setShowBooleanMsg(false)
			}
			if (
				fieldType !== 'date' &&
				fieldType !== 'long' &&
				fieldType !== 'integer' &&
				fieldType !== 'boolean'
			) {
				if (keyField !== filterField || isDeleteModeOn) {
					setFilterOptions([])
					setDisableValueSelector(true)
					const valueDetail = imageDetail.filter((val) => val?.value === filterField)
					generateConfig(
						indexValue,
						indexAlias,
						aliasFlag,
						valueDetail,
						observedAtDetail.startDate,
						observedAtDetail.endDate,
					)
						.then((res) => {
							if (res?.fields) {
								setKeyField(filterField)
								const fieldDetail = res.fields
								setFilterOptions(
									fieldDetail[filterField]?.options ? fieldDetail[filterField].options : [],
								)
								setDisableValueSelector(false)
							}
						})
						.catch((error) => {
							setDisableValueSelector(false)
							console.log('error: ', error)
						})
				}
			}
		} catch (error) {
			console.log(error)
		}
	}

	const [errorMessage, setErrorMessage] = useState('')
	const onChangeFilterVal = (e, text) => {
		try {
			if (e) {
				setDisabled(false)
				setNonNumeric(false)
				setErrorMessage('')
				setLowerRange('')
				setHigherRange('')
				setFilterMatched(false)
				if (text === 'input') {
					if (e?.target?.value) {
						e = e.target.value.trim()
					} else {
						setDisabled(true)
					}
					if (e === '') {
						setDisabled(true)
					}
					if (isNaN(e) && mappingProps[filterField].type !== 'boolean') {
						setNonNumeric(true)
						setDisabled(true)
					}
					setFilterValue(e)
				} else if (text === 'textfield') {
					if (e?.target?.value) {
						e = e.target.value.trim()
					} else {
						setDisabled(true)
					}
					if (e === '') {
						setDisabled(true)
					}
					setFilterValue(e)
				} else {
					if (e === '') {
						setDisabled(true)
					}
					setFilterValue(e)
				}
			}
		} catch (error) {
			console.log(error)
		}
	}

	const onSearchChange = (value) => {
		try {
			if (value) {
				getSuggestedValues(
					filterField,
					value,
					mappingProps,
					indexValue,
					indexAlias,
					aliasFlag,
					observedAtDetail,
					filterOptions,
				).then((opts) => {
					if (opts?.length) {
						setFilterOptions(opts)
					}
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const onTimeChange = (e) => {
		try {
			setDateError(false)
			setDisabled(false)
			setFilterMatched(false)
			if (e.isInvalid) {
				setDateError(true)
				setDisabled(true)
				return
			}
			setStart(e.start)
			setEnd(e.end)
			// eslint-disable-next-line
			let st = moment(dateMath.parse(e.start)).format('YYYY-MM-DDTHH:mm:ss')
			let et = moment(
				dateMath.parse(
					e.end === 'now/d' || e.end === 'now/w' || e.end === 'now/M' || e.end === 'now/y'
						? 'now'
						: e.end,
				),
			).format(e.end === 'now-1d/d' ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm:ss')
			if (e.end === 'now-1d/d') {
				et = `${et}T23:59:59`
			}
			// eslint-disable-next-line
			const formattedStart = moment.tz(st, timezone).format()
			const formattedEnd = moment.tz(et, timezone).format()
			setFilterValue(`${formattedStart} AND ${formattedEnd}`)
		} catch (error) {
			console.log(error)
		}
	}
	const [filterMatched, setFilterMatched] = useState(false)
	const saveFilter = () => {
		try {
			if (lowerRange || higherRange) {
				filterValue = lowerRange + ' AND ' + higherRange
			}
			if (filterField && filterValue) {
				let qv = ''
				let op = ''
				if (operand === 'is') {
					qv = `${filterField} = ${filterValue ? filterValue : ''}`
					op = '='
				} else if (operand === 'is not') {
					qv = `${filterField} <> ${filterValue ? filterValue : ''}`
					op = '<>'
				} else if (operand === 'is between') {
					qv = `${filterField} BETWEEN ${filterValue ? filterValue : ''}`
					op = 'BETWEEN'
				} else if (operand === 'is not between') {
					qv = `${filterField} NOT BETWEEN ${filterValue ? filterValue : ''}`
					op = 'NOT BETWEEN'
				} else if (operand === 'Like') {
					qv = `${filterField} LIKE ${filterValue ? filterValue : ''}`
					op = 'LIKE'
				} else if (operand === 'Not like') {
					qv = `${filterField} NOT LIKE ${filterValue ? filterValue : ''}`
					op = 'NOT LIKE'
				}
				let isMatched = false
				if (queryStack?.length) {
					queryStack.forEach((val) => {
						if (val) {
							let existingField = val.field ? val.field.toLowerCase() : ''
							let newField = filterField ? filterField.toLowerCase() : ''
							let existingVal = val.fieldValue ? val.fieldValue.toLowerCase().trim() : ''
							let newVal = filterValue ? filterValue.toLowerCase().trim() : ''
							let existingOperator = val.operator ? val.operator.toLowerCase() : ''
							let newOperator = op ? op.toLowerCase() : ''
							if (
								existingVal === newVal &&
								existingField === newField &&
								existingOperator === newOperator
							) {
								setFilterMatched(true)
								isMatched = true
							}
						}
					})
				}
				if (!isMatched) {
					if (qv) {
						setAddFilter(false)
						dispatch(
							saveQueryStack([
								...queryStack,
								{
									query: qv,
									isChecked: true,
									field: filterField,
									fieldValue: filterValue,
									operand: 'AND',
									group: false,
									allowOperand: true,
									braces: '',
									operator: op,
								},
							]),
						)
					}
					setOperand([])
					setFilterValue([])
					setFilterField([])
					setShowDateText(false)
				}
			}
		} catch (error) {
			console.log('Error in saveFilter function: ', error)
		}
	}

	const onLowerRangeChange = (e) => {
		try {
			if (higherRange !== '') {
				setDisabled(false)
			}
			setFilterMatched(false)
			setLowerRange(e?.target?.value ? e.target.value.trim() : '')
		} catch (error) {
			console.log(error)
		}
	}

	const onHigherRangeChange = (e) => {
		try {
			if (lowerRange !== '') {
				setDisabled(false)
			}
			setFilterMatched(false)
			setHigherRange(e?.target?.value ? e.target.value.trim() : '')
		} catch (error) {
			console.log(error)
		}
	}

	const onBooleanChange = (checked) => {
		try {
			setFilterValue(`${checked}`)
			setFilterMatched(false)
		} catch (error) {
			console.log(error)
		}
	}

	const optionFiltersForDelete = imageDetail.filter((el) =>
		getAllowedFiltersForDelete().includes(el.label),
	)

	const renderDeleteContent = () => (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					gap: '10px',
					alignItems: 'center',
				}}
			>
				<Select
					showSearch
					id='filterField'
					style={{ width: 150 }}
					placeholder='Select a field first'
					optionFilterProp='children'
					value={filterField}
					onChange={onSelectFilterField}
					disabled={disableValueSelector}
					filterOption={(input, option) =>
						option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
				>
					{optionFiltersForDelete.length > 0 &&
						optionFiltersForDelete.map((element, index) => {
							if (
								element.label !== 'observed_at' &&
								element.label !== 'masked_text' &&
								element.type !== 'nested'
							) {
								return (
									<Option key={index} value={element.label}>
										{element.label}
									</Option>
								)
							} else {
								return null
							}
						})}
				</Select>
				<Select
					showSearch
					style={{ width: 150 }}
					placeholder='Select'
					optionFilterProp='children'
					id='operandField'
					onChange={onSelectOperand}
					disabled={disableValueSelector}
					value={operand}
					filterOption={(input, option) =>
						option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
				>
					{operandOptions.length > 0 &&
						operandOptions.map((element, index) => {
							return (
								<Option key={index} value={element.label}>
									{element.label}
								</Option>
							)
						})}
				</Select>
				<Select
					showSearch
					style={{ width: 150 }}
					placeholder='Select a value'
					optionFilterProp='children'
					id='filtervalselect'
					disabled={disableValueSelector}
					loading={disableValueSelector}
					onChange={onChangeFilterVal}
					value={filterValue}
					onSearch={onSearchChange}
					filterOption={(input, option) =>
						option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
				>
					{filterOptions.length > 0 &&
						filterOptions.map((element, index) => {
							return (
								<Option key={index} value={element.label}>
									{element.label}
								</Option>
							)
						})}
				</Select>
				<Button shape='circle' onClick={saveFilter} disabled={disabled} id='savefilter'>
					<PlusOutlined />
				</Button>
			</div>
			{filterMatched ? <div className='color-red'>This filter already exists.</div> : null}
			{errorMessage && <div className='color-red'>{errorMessage}</div>}
		</>
	)

	const renderContent = () => (
		<div className='add-filters-section'>
			<Row>
				<Col className='filterdropdown' span={8}>
					<span>Field</span>
					<div className='dropdown' style={{ margin: '5px auto', width: '430px' }}>
						<Select
							showSearch
							id='filterField'
							style={{ width: 270 }}
							placeholder='Select a field first'
							optionFilterProp='children'
							onChange={onSelectFilterField}
							disabled={disableValueSelector}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{imageDetail.length > 0 &&
								imageDetail.map((element, index) => {
									if (
										element.label !== 'observed_at' &&
										element.label !== 'masked_text' &&
										element.type !== 'nested'
									) {
										return (
											<Option key={index} value={element.label}>
												{element.label}
											</Option>
										)
									} else {
										return null
									}
								})}
						</Select>
					</div>
				</Col>
				<Col className='filterdropdown' span={8}>
					<span>Operand</span>
					<div className='dropdown' style={{ margin: '5px auto' }}>
						<Select
							showSearch
							style={{ width: 270 }}
							placeholder='Select'
							optionFilterProp='children'
							id='operandField'
							onChange={onSelectOperand}
							disabled={disableValueSelector}
							value={operand}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{operandOptions.length > 0 &&
								operandOptions.map((element, index) => {
									return (
										<Option key={index} value={element.label}>
											{element.label}
										</Option>
									)
								})}
						</Select>
					</div>
				</Col>
				{operand && (operand === 'is' || operand === 'is not') ? (
					<Col className='filterdropdown' span={8}>
						<span>Value</span>
						<div style={{ margin: '5px auto' }}>
							{showFilterSelect ? (
								<Select
									showSearch
									style={{ width: 270 }}
									placeholder='Select a value'
									optionFilterProp='children'
									id='filtervalselect'
									disabled={disableValueSelector}
									loading={disableValueSelector}
									onChange={onChangeFilterVal}
									onSearch={onSearchChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
								>
									{filterOptions.length > 0 &&
										filterOptions.map((element, index) => {
											return (
												<Option key={index} value={element.label}>
													{element.label}
												</Option>
											)
										})}
								</Select>
							) : showBooleanMsg ? (
								<Switch
									checkedChildren='True'
									id='booleanField'
									unCheckedChildren='False'
									onClick={onBooleanChange}
									style={{ backgroundColor: '#F05742' }}
								/>
							) : (
								<EuiFieldText
									placeholder='Enter a value'
									onChange={(e) => onChangeFilterVal(e, 'input')}
									maxLength={18}
								/>
							)}
						</div>
					</Col>
				) : null}
				{operand[0] && (operand === 'is between' || operand === 'is not between') ? (
					<Col className='filterdropdown' span={8} style={{ paddingTop: '27px' }}>
						<div style={{ margin: '5px auto' }}>
							{showDateText ? (
								<EuiSuperDatePicker
									id='datePicker'
									showUpdateButton={false}
									onTimeChange={onTimeChange}
									start={start}
									end={end}
								/>
							) : (
								<EuiFormControlLayoutDelimited
									startControl={
										<input
											type='text'
											placeholder='Start of range'
											className='euiFieldNumber'
											onChange={onLowerRangeChange}
											value={lowerRange}
											id='lowerrange'
											aria-label='Use aria labels when no actual label is in use'
											maxLength={18}
										/>
									}
									endControl={
										<input
											type='text'
											placeholder='End of range'
											className='euiFieldNumber'
											id='higherrange'
											onChange={onHigherRangeChange}
											value={higherRange}
											aria-label='Use aria labels when no actual label is in use'
											maxLength={18}
										/>
									}
								/>
							)}
						</div>
					</Col>
				) : null}
				{operand && (operand === 'Like' || operand === 'Not like') && (
					<Col className='filterdropdown' span={8}>
						<span>Value</span>
						<div style={{ margin: '5px auto' }}>
							<EuiFieldText
								placeholder='Enter a value'
								id='textField'
								onChange={(e) => onChangeFilterVal(e, 'textfield')}
							/>
						</div>
					</Col>
				)}
			</Row>
			<Row>
				<Col className='paddingError'>
					{rangeError ? (
						<div className='color-red'>End range should be greater than start range.</div>
					) : null}
					{dateError ? (
						<div className='color-red'>End date should be higher than start date</div>
					) : null}
					{nonNumeric ? <div className='color-red'>Please enter numeric value</div> : null}
					{filterMatched ? <div className='color-red'>This filter already exists.</div> : null}
					{errorMessage && <div className='color-red'>{errorMessage}</div>}
				</Col>
			</Row>
			<Row className='add-filters-footer'>
				<EuiButton fill size='s' onClick={saveFilter} disabled={disabled} id='savefilter'>
					Save
				</EuiButton>
				<EuiButton size='s' onClick={closePopover3} style={{ marginLeft: '10px' }}>
					Cancel
				</EuiButton>
			</Row>
		</div>
	)

	if (isDeleteModeOn) return renderDeleteContent()

	return renderContent()
}

AddFilter.propTypes = {
	setFilterValue: PropsTypes.func,
	filterValue: PropsTypes.any,
	lowerRange: PropsTypes.string,
	higherRange: PropsTypes.string,
	setHigherRange: PropsTypes.func,
	setLowerRange: PropsTypes.func,
	setAddFilter: PropsTypes.func,
	clearFilter: PropsTypes.bool,
}

export default AddFilter
