import { QuestionCircleFilled } from '@ant-design/icons'
import { Button, Col, Form, Input, Row, Switch } from 'antd'
import axios from 'axios'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ROOT from '../../client'
import { saveScreenSignature } from '../../store/actions'
import customTost from '../Notification'
import './filter.scss'

const UpdateSignature = (props) => {
	const dispatch = useDispatch()
	const {
		indexValue,
		indexAlias,
		aliasFlag,
		filters,
		observedAtDetail,
		areaQuery,
		mappingImageKey,
	} = useSelector((store) => store.storeProps)
	const { handleCancel, setUpdateSignatureFlag } = props
	const [form] = Form.useForm()
	const [isLoading, setIsLoading] = useState(false)

	const onFinish = (formValues) => {
		try {
			if (formValues) {
				setIsLoading(true)
				const url = `${ROOT}/api/screen-sig`
				const payload = {
					index: indexValue,
					alias: aliasFlag ? indexAlias : '',
					groups: filters ? filters : '',
					date: observedAtDetail && observedAtDetail.dateRange ? observedAtDetail.dateRange : '',
					screenSignature: formValues?.signatureVal ? formValues.signatureVal.trim() : '',
					area_coordinates:
						areaQuery && areaQuery.area_coordinates && areaQuery.area_coordinates.length
							? areaQuery.area_coordinates
							: [],
					startRange:
						areaQuery && areaQuery.startRange && areaQuery.startRange.length
							? areaQuery.startRange
							: [],
					endRange:
						areaQuery && areaQuery.endRange && areaQuery.endRange.length ? areaQuery.endRange : [],
					mappingKey: mappingImageKey,
					ignoreVersionConflicts: formValues.ignoreVersionConflicts,
				}
				axios
					.put(url, payload)
					.then((resp) => {
						if (resp?.data && resp?.data?.statusCode === 200) {
							let totalRecords = ''
							let updatedRecords = ''
							let versionConflicts = ''
							if (resp?.data?.body) {
								totalRecords = resp.data.body.total ? resp.data.body.total : ''
								updatedRecords = resp.data.body.updated ? resp.data.body.updated : ''
								versionConflicts = resp.data.body.version_conflicts
									? resp.data.body.version_conflicts
									: ''
							}
							setIsLoading(false)
							form.resetFields()
							setUpdateSignatureFlag(false)
							customTost({
								type: 'success',
								message: 'Screen signature updated successfully.',
								description: (
									<>
										{versionConflicts && (
											<>
												<strong>
													Total records: {totalRecords}
													<br></br>
													Updated records: {updatedRecords}
													<br></br>
													Version Conflicts: {versionConflicts}
												</strong>
											</>
										)}
									</>
								),
								duration: 10,
							})
							dispatch(saveScreenSignature(formValues.signatureVal ? formValues.signatureVal : ''))
						}
					})
					.catch((error) => {
						const errMsg = error?.response?.data?.message
							? error.response.data.message.indexOf('Reason: no permissions') !== -1
								? // eslint-disable-next-line quotes
								  "You don't have permissions to update"
								: error.response.data.message
							: ''
						let totalRecords = ''
						let updatedRecords = ''
						let versionConflicts = ''
						if (error?.response?.data?.body) {
							totalRecords = error.response.data.body.total ? error.response.data.body.total : ''
							updatedRecords = error.response.data.body.updated
								? error.response.data.body.updated
								: ''
							versionConflicts = error.response.data.body.version_conflicts
								? error.response.data.body.version_conflicts
								: ''
						}
						if (errMsg) {
							customTost({
								type: 'error',
								message: errMsg,
								description: (
									<>
										{versionConflicts && (
											<>
												<strong>
													Total records: {totalRecords}
													<br></br>
													Updated records: {updatedRecords}
													<br></br>
													Version Conflicts: {versionConflicts}
												</strong>
											</>
										)}
									</>
								),
								duration: 10,
							})
						}
						setIsLoading(false)
					})
			}
		} catch (error) {
			console.log('function onFinish error: ', error)
		}
	}
	const handleUpdateCancel = () => {
		handleCancel()
		form.resetFields()
	}
	return (
		<div className='add-filters-section'>
			<Row className='updateSignature'>
				<Col span={24} className='updateCol'>
					<h3>Update Screen Signature</h3>
				</Col>
				<Col span={12} className='updateCol'>
					<Form
						form={form}
						name='updateScreenSignatureform'
						onFinish={onFinish}
						layout='vertical'
						className='form-update-signature'
					>
						<Form.Item
							label='Signature'
							name='signatureVal'
							rules={[
								{
									validator(_, value) {
										if (value) {
											value = value.trim()
											if (value) {
												if (value.indexOf('"') !== -1) {
													return Promise.reject(
														new Error('Value is not valid. Please remove double quote.'),
													)
												} else if (value.indexOf('\\') !== -1) {
													return Promise.reject(
														new Error('Value is not valid. Please remove backward slash.'),
													)
												} else {
													return Promise.resolve()
												}
											} else {
												return Promise.reject(new Error('Required field.'))
											}
										} else {
											return Promise.reject(new Error('Required field.'))
										}
									},
								},
							]}
						>
							<Input id='signatureVal' placeholder='Enter value' />
						</Form.Item>
						<Form.Item
							label={<>Ignore Version Conflicts</>}
							name='ignoreVersionConflicts'
							tooltip={{
								title:
									'Conflicts can occur while updating screen signature if some documents are being added in the database. Please set this value to true if you want to ignore the conflicted documents and update the remaining filtered documents.',
								icon: <QuestionCircleFilled />,
							}}
							valuePropName='checked'
							className='checkConflict'
							initialValue={false}
						>
							<Switch
								checkedChildren='True'
								unCheckedChildren='False'
								style={{ backgroundColor: '#F05742' }}
							/>
						</Form.Item>
						<Form.Item>
							<Button
								className='cancelUpdateSign'
								disabled={isLoading}
								onClick={handleUpdateCancel}
							>
								Cancel
							</Button>
							<Button loading={isLoading} type='primary' htmlType='submit'>
								Update Signature
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</div>
	)
}

UpdateSignature.propTypes = {
	handleCancel: PropTypes.func,
	setUpdateSignatureFlag: PropTypes.func,
}

export default UpdateSignature
