import { ImportOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import './importQuery.scss'

const ImportQuery = (props) => {
	const { setImportFile, setUpdateSignatureFlag, setAddFilter, setEditFilter } = props
	const handleImport = () => {
		setImportFile(true)
		setUpdateSignatureFlag(false)
		setAddFilter(false)
		setEditFilter(false)
	}
	return (
		<Button id='importQueryButton' icon={<ImportOutlined />} type='primary' onClick={handleImport}>
			Import Query
		</Button>
	)
}

ImportQuery.propTypes = {
	setImportFile: PropTypes.func,
	setUpdateSignatureFlag: PropTypes.func,
	setAddFilter: PropTypes.func,
	setEditFilter: PropTypes.func,
}

export default ImportQuery
